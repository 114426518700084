import React, { useEffect, useState } from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import { BarChart } from "@mui/x-charts/BarChart";
import visitorsService from "../../services/visitors";
import { instanceToken } from "../../utils/constant";
import dashboardService from "../../services/dashboard";
import customerMessageService from "../../services/customer_message";
import contactService from "../../services/contact";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import WhereToVoteIcon from "@mui/icons-material/WhereToVote";
import HotelIcon from "@mui/icons-material/Hotel";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  List,
  ListItemButton,
  ListItem,
  ListItemText,
  Card,
  Box,
  CardContent,
  Typography,
} from "@mui/material";
import RestaurantIcon from "@mui/icons-material/Restaurant";

const months = [];
const event = new Date();
for (let i = 0; i < 6; i++) {
  months.push(event.toLocaleDateString("mmt", { month: "short" }));
  event.setMonth(event.getMonth() - 1);
}
months.reverse();
const now = new Date();
const date = `${now.getFullYear()}-${now.getMonth() + 1}`;

const Dashboard = () => {
  const [lines, setLines] = useState([]);
  const [bars, setBars] = useState({});
  const [blogs, setBlogs] = useState([]);
  const [places, setPlaces] = useState([]);
  const [counts, setCounts] = useState({});
  const [customerMessages, setCustomerMessage] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [users, setUsers] = useState([
    { label: "Signed", value: 230 },
    { label: "Unsigned", value: 30 },
  ]);

  useEffect(() => {
    if (lines.length < 6) {
      fetchLineData();
    }
    fetchVisitors();
    fetchTop3Blogs();
    fetchTop3Places();
    fetchCounts();
    fetchCustomerMessages(3, 0);
    fetchContacts(3, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchContacts = async (limit, offset) => {
    try {
      const res = await contactService.getContacts(
        instanceToken.token,
        limit,
        offset
      );
      setContacts(res.data.contacts);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };

  const fetchCustomerMessages = async (limit, offset) => {
    try {
      const res = await customerMessageService.getCustomerMessages(
        instanceToken.token,
        limit,
        offset
      );
      setCustomerMessage(res.data.customer_messages);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };

  const fetchCounts = async () => {
    try {
      const res = await dashboardService.getCounts();
      setCounts(res.data);
      setUsers([
        { label: "Signed", value: res.data.user },
        { label: "Unsigned", value: res.data.visitor - res.data.user },
      ]);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };

  const fetchLineData = async () => {
    try {
      const res = await visitorsService.getSixMonthsVisitors(
        instanceToken.token,
        date
      );
      setLines(res.data.reverse());
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };

  const fetchVisitors = async () => {
    try {
      const res = await visitorsService.getVisitors(instanceToken.token);
      parse(res.data);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };

  const fetchTop3Blogs = async () => {
    try {
      const res = await visitorsService.getTop3Blogs(instanceToken.token);
      if (res.data) {
        const parsedData = res.data.map((item) => {
          return {
            id: item.blogId,
            value: item.no_visitors,
            label: `${item.blog.title}`,
          };
        });
        setBlogs(parsedData);
      }
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };

  const fetchTop3Places = async () => {
    try {
      const res = await visitorsService.getTop3Places(instanceToken.token);
      const parsedData = res.data.map((item) => {
        return {
          id: item.placeId,
          value: item.no_visitors,
          label: `${item.place.name}`,
        };
      });
      setPlaces(parsedData);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };

  const parse = (data) => {
    const b = {};
    data.forEach((d) => {
      if (b[d.timezone]) {
        b[d.timezone] = b[d.timezone] + 1;
      } else {
        b[d.timezone] = 1;
      }
    });
    setBars(b);
  };

  const barData = Object.values(bars);
  const barLable = Object.getOwnPropertyNames(bars);

  return (
    <>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 5fr",
          gap: 3,
          mb: 2,
        }}
      >
        <Paper>
          <Typography
            variant="h6"
            component="h6"
            sx={{ px: 2, py: 1, fontSize: "0.8rem" }}
          >
            All Visitors
          </Typography>
          {users.length === 2 && (
            <PieChart
              series={[
                {
                  paddingAngle: 5,
                  innerRadius: 30,
                  outerRadius: 80,
                  data: users,
                  arcLabel: (item) => `${item.label}`,
                },
              ]}
              sx={{
                [`& .${pieArcLabelClasses.root}`]: {
                  fill: "white",
                  fontSize: 10,
                },
              }}
              margin={{ right: 5 }}
              width={300}
              height={200}
              legend={{ hidden: true }}
            />
          )}
        </Paper>
        <Paper
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            gap: 3,
            alignItems: "center",
            p: 2,
          }}
        >
          <Card
            className="count-container"
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 3fr",
              alignItems: "center",
            }}
          >
            <CardContent sx={{ justifyContent: "center" }}>
              <WhereToVoteIcon className="nav-link-icon" />
            </CardContent>
            <CardContent>
              <Typography variant="h5" component="h5">
                {counts.place}
              </Typography>
              <Typography variant="p" component="p" color="gray">
                Places
              </Typography>
            </CardContent>
          </Card>
          <Card
            className="count-container"
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 3fr",
              alignItems: "center",
            }}
          >
            <CardContent sx={{ justifyContent: "center" }}>
              <NewspaperIcon className="nav-link-icon" />
            </CardContent>
            <CardContent>
              <Typography variant="h5" component="h5">
                {counts.blog}
              </Typography>
              <Typography variant="p" component="p" color="gray">
                Blogs
              </Typography>
            </CardContent>
          </Card>
          <Card
            className="count-container"
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 3fr",
              alignItems: "center",
            }}
          >
            <CardContent sx={{ justifyContent: "center" }}>
              <RestaurantIcon className="nav-link-icon" />
            </CardContent>
            <CardContent>
              <Typography variant="h5" component="h5">
                {counts.restaurant}
              </Typography>
              <Typography variant="p" component="p" color="gray">
                Restaurants
              </Typography>
            </CardContent>
          </Card>
          <Card
            className="count-container"
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 3fr",
              alignItems: "center",
            }}
          >
            <CardContent sx={{ justifyContent: "center" }}>
              <HotelIcon className="nav-link-icon" />
            </CardContent>
            <CardContent>
              <Typography variant="h5" component="h5">
                {counts.hotel}
              </Typography>
              <Typography variant="p" component="p" color="gray">
                Hotels
              </Typography>
            </CardContent>
          </Card>
        </Paper>
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "7fr 5fr",
          gap: 2,
          alignItems: "start",
        }}
      >
        <Box sx={{ height: "100%" }}>
          {contacts.length > 0 && (
            <TableContainer component={Paper}>
              <Typography
                variant="h6"
                component="h6"
                sx={{ py: 1, pl: 2, fontSize: "0.8rem" }}
              >
                Latest Contacts
              </Typography>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Content</TableCell>
                    <TableCell>Created On</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {contacts.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>
                        {row.content?.substring(0, 30)} ...{" "}
                      </TableCell>
                      <TableCell>{row?.createdAt.substring(0, 10)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <Paper
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: 3,
              alignItems: "center",
              my: 2,
              px: 2,
            }}
          >
            <Box
              sx={{
                height: "100%",
              }}
            >
              <Typography
                variant="h6"
                component="h6"
                sx={{ py: 1, fontSize: "0.8rem" }}
              >
                Top Three Most Visited Places
              </Typography>
              <PieChart
                series={[
                  {
                    data: places,
                    paddingAngle: 3,
                    innerRadius: 3,
                    cornerRadius: 8,
                    startAngle: -130,
                    endAngle: 140,
                    arcLabel: (item) => `ID : ${item.id}`,
                  },
                ]}
                height={200}
                margin={{ right: 5 }}
                sx={{
                  [`& .${pieArcLabelClasses.root}`]: {
                    fill: "white",
                    fontSize: 10,
                  },
                }}
                legend={{ hidden: true }}
              />
              <List>
                {places.map((p, index) => (
                  <ListItem key={index} disablePadding>
                    <ListItemButton
                      sx={{ p: 0.2, px: 1 }}
                      component="a"
                      href={`/place/${p.id}`}
                    >
                      <ListItemText
                        className="top-list"
                        primary={`${p.label.substring(0, 40)} ...`}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
            <Box>
              <Typography
                variant="h6"
                component="h6"
                sx={{ py: 1, fontSize: "0.8rem" }}
              >
                Top Three Most Visited Blogs
              </Typography>
              <PieChart
                series={[
                  {
                    data: blogs,
                    paddingAngle: 3,
                    innerRadius: 3,
                    cornerRadius: 8,
                    startAngle: -130,
                    endAngle: 140,
                    arcLabel: (item) => `ID: ${item.id}`,
                  },
                ]}
                sx={{
                  [`& .${pieArcLabelClasses.root}`]: {
                    fill: "white",
                    fontSize: 10,
                  },
                }}
                margin={{ right: 5 }}
                legend={{ hidden: true }}
                height={200}
              />
              <List>
                {blogs.map((b, index) => (
                  <ListItem key={index} disablePadding>
                    <ListItemButton
                      sx={{ p: 0.2, px: 1 }}
                      component="a"
                      href={`/blog/${b.id}`}
                    >
                      <ListItemText
                        className="top-list"
                        primary={`${b.label.substring(0, 40)} ...`}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Paper>
          {customerMessages.length > 0 && (
            <TableContainer component={Paper}>
              <Typography
                variant="h6"
                component="h6"
                sx={{ py: 1, pl: 2, fontSize: "0.8rem" }}
              >
                Latest Messages From Customers
              </Typography>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Customer ID</TableCell>
                    <TableCell>Message</TableCell>
                    <TableCell>Created On</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customerMessages.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={
                        row.read
                          ? {
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }
                          : {
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                              backgroundColor: "#d4d4d4",
                            }
                      }
                    >
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell>{row.user?.id}</TableCell>
                      <TableCell>
                        {row.message?.substring(0, 60)} ...{" "}
                      </TableCell>
                      <TableCell>{row?.createdAt?.substring(0, 10)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
        <Paper>
          <Box>
            <Typography
              variant="h6"
              component="h6"
              sx={{ py: 1, pl: 2, fontSize: "0.8rem" }}
            >
              Visitors Overview In Six Months
            </Typography>
            {lines.length === 6 && (
              <LineChart
                yAxis={[
                  {
                    label: "Number of visitor",
                  },
                ]}
                xAxis={[{ scaleType: "point", data: months }]}
                series={[
                  {
                    data: lines,
                    area: true,
                    label: "Visitor",
                  },
                ]}
                height={400}
                sx={{ py: 5 }}
              />
            )}
          </Box>

          <Typography
            variant="h6"
            component="h6"
            sx={{ py: 1, pl: 2, fontSize: "0.8rem" }}
          >
            Visitors By Country
          </Typography>
          {barLable.length > 0 && barData.length > 0 && (
            <BarChart
              yAxis={[
                {
                  scaleType: "band",
                  data: barLable,
                },
              ]}
              xAxis={[
                {
                  label: "Number of visitor",
                },
              ]}
              series={[
                {
                  data: barData,
                  label: "Visitor",
                },
              ]}
              height={400}
              layout="horizontal"
              sx={{ py: 5 }}
            />
          )}
        </Paper>
      </Box>
    </>
  );
};

export default Dashboard;
