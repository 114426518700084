import axios from "axios";
import { BACKEND_URL } from "../utils/constant";

const getBlogs = async (limit, offset) => {
  const response = await axios.get(
    `${BACKEND_URL}/api/blogs?limit=${limit}&offset=${offset}`
  );
  return response;
};

const getBlog = async (id) => {
  const response = await axios.get(`${BACKEND_URL}/api/blogs/${id}`);
  return response;
};

const putBlog = async (token, id, data) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.put(
    `${BACKEND_URL}/api/blogs/${id}`,
    data,
    config
  );
  return response;
};

const postBlog = async (token, data) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.post(`${BACKEND_URL}/api/blogs`, data, config);
  return response;
};

const deleteBlog = async (token, id) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.delete(`${BACKEND_URL}/api/blogs/${id}`, config);
  return response;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getBlogs,
  putBlog,
  postBlog,
  deleteBlog,
  getBlog,
};
