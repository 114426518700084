import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import blogService from "../../../services/blog";
import uploadService from "../../../services/upload";
import {
  Container,
  Card,
  Grid,
  Box,
  Typography,
  CardContent,
  CardActions,
  Button,
  Breadcrumbs,
  Modal,
  Alert,
  CardMedia,
} from "@mui/material";
import { instanceToken } from "../../../utils/constant";

const styleR = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Blog = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [openR, setOpenR] = useState(false);
  const [blog, setBlog] = useState(null);
  const [showAlert, setShowAlert] = useState({ message: "", isError: false });

  useEffect(() => {
    if (!blog) {
      fetchBlog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchBlog = async () => {
    try {
      const res = await blogService.getBlog(id);
      setBlog(res.data);
    } catch (error) {
      console.error("error : ", error);
      throw new Error(error);
    }
  };

  if (!blog) {
    return <em className="placeholder">Loading...</em>;
  }

  const handleOpenR = () => {
    setOpenR(true);
  };
  const handleCloseR = () => {
    setOpenR(false);
  };

  const handleRemove = async () => {
    try {
      if (blog.picture) {
        await uploadService.deleteImage(instanceToken.token, {
          imageName: blog.picture,
        });
      }
      await blogService.deleteBlog(instanceToken.token, blog.id);
      setShowAlert({
        message: "Blog have been removed.",
        isError: false,
      });
    } catch (error) {
      console.log(error);
      setShowAlert({
        message: "Error on server!",
        isError: true,
      });
    } finally {
      handleCloseR();
      setTimeout(() => {
        setShowAlert({ message: "", isError: false });
        navigate("/blogs");
      }, 4000);
    }
  };

  return (
    <>
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" to="/blogs">
            blogs
          </Link>
          <Typography color="text.primary">blog (ID - {id})</Typography>
        </Breadcrumbs>
      </div>
      {showAlert.message ? (
        <em className="placeholder">
          It will be automatic navigated to blogs page soon.
        </em>
      ) : (
        <Container
          maxWidth={false}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            mt: 2,
          }}
        >
          <Card
            sx={{
              minWidth: "70vw",
              display: "grid",
              gridTemplateColumns: "2fr 3fr",
            }}
          >
            <div>
              <CardMedia
                component="img"
                height="294"
                image={blog.picture}
                sx={{ mr: 3 }}
              />
              <CardContent sx={{ pr: 5 }}>
                <Grid sx={{ m: 2 }} container spacing={1}>
                  <Grid item xs={4}>
                    <Typography variant="body2" color="text.secondary">
                      Title :
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body2" color="text.secondary">
                      {blog.title}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid sx={{ m: 2 }} container spacing={1}>
                  <Grid item xs={4}>
                    <Typography variant="body2" color="text.secondary">
                      Title (Myanmar) :
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body2" color="text.secondary">
                      {blog.titleMm}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid sx={{ m: 2 }} container spacing={1}>
                  <Grid item xs={4}>
                    <Typography variant="body2" color="text.secondary">
                      Topic :
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body2" color="text.secondary">
                      {blog.topic}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid sx={{ m: 2 }} container spacing={1}>
                  <Grid item xs={4}>
                    <Typography variant="body2" color="text.secondary">
                      Topic (Myanmar) :
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body2" color="text.secondary">
                      {blog.topicMm}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid sx={{ m: 2 }} container spacing={1}>
                  <Grid item xs={4}>
                    <Typography variant="body2" color="text.secondary">
                      Created On :
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body2" color="text.secondary">
                      {blog.createdAt.substring(0, 10)}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </div>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  p: 2,
                  bgcolor: "#f7f7f5",
                  borderRadius: 2,
                  marginInlineStart: 1,
                }}
              >
                <div
                  dangerouslySetInnerHTML={{ __html: blog.description }}
                ></div>
              </Box>
              <Box
                sx={{
                  flex: 1,
                  p: 2,
                  bgcolor: "#f7f7f5",
                  borderRadius: 2,
                  marginInlineStart: 1,
                }}
              >
                <div
                  dangerouslySetInnerHTML={{ __html: blog.descriptionMm }}
                ></div>
              </Box>
            </Box>
            <CardActions sx={{ gridColumn: "1/3", justifyContent: "end" }}>
              <Button size="small" color="error" onClick={handleOpenR}>
                Remove
              </Button>
              <Button
                size="small"
                component={Link}
                to={`/updateBlog/${blog.id}`}
              >
                Edit
              </Button>
            </CardActions>
          </Card>
        </Container>
      )}

      <Modal
        keepMounted
        open={openR}
        onClose={handleCloseR}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={styleR}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Confirmation
          </Typography>
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
            Are you sure want to remove it?
          </Typography>
          <Box sx={{ textAlign: "right", mt: 2 }}>
            <Button color="secondary" onClick={handleCloseR}>
              Cancel
            </Button>
            <Button onClick={() => handleRemove()}>Confirm</Button>
          </Box>
        </Box>
      </Modal>
      {showAlert.message && !showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="success"
        >
          {showAlert.message}
        </Alert>
      )}
      {showAlert.message && showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="warning"
        >
          {showAlert.message}
        </Alert>
      )}
    </>
  );
};

export default Blog;
