import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import roomService from "../../../services/room";
import uploadService from "../../../services/upload";
import {
  Container,
  Card,
  Grid,
  Box,
  Typography,
  CardContent,
  CardActions,
  Button,
  Breadcrumbs,
  Modal,
  Alert,
  ImageList,
  ImageListItem,
} from "@mui/material";
import { instanceToken } from "../../../utils/constant";

const styleR = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Room = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [openR, setOpenR] = useState(false);
  const [room, setRoom] = useState(null);
  const [showAlert, setShowAlert] = useState({ message: "", isError: false });

  useEffect(() => {
    if (!room) {
      fetchRoom();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchRoom = async () => {
    try {
      const res = await roomService.getRoom(id);
      setRoom(res.data);
    } catch (error) {
      console.error("error : ", error);
      throw new Error(error);
    }
  };

  if (!room) {
    return <em className="placeholder">Loading...</em>;
  }

  const handleOpenR = () => {
    setOpenR(true);
  };
  const handleCloseR = () => {
    setOpenR(false);
  };

  const deleteImage = async (fileName) => {
    try {
      await uploadService.deleteImage(instanceToken.token, {
        imageName: fileName,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleRemove = async () => {
    try {
      if (room.pictures) {
        room.pictures.split("||").forEach(async (picture) => {
          let image_name = picture.substring(
            picture.lastIndexOf("/") + 1,
            picture.length
          );
          await deleteImage(image_name);
        });
      }
      await roomService.deleteRoom(instanceToken.token, room.id);
      setShowAlert({
        message: "Room have been removed.",
        isError: false,
      });
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        setShowAlert({
          message: "Unauthorized",
          isError: true,
        });
      } else {
        setShowAlert({
          message: "Error on server!",
          isError: true,
        });
      }
    } finally {
      handleCloseR();
      setTimeout(() => {
        setShowAlert({ message: "", isError: false });
        navigate(`/hotel/${room.hotelId}`);
      }, 4000);
    }
  };

  return (
    <>
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb">
          {instanceToken.role === "master" && (
            <Link
              underline="hover"
              color="inherit"
              to={`/hotel/${room.hotelId}`}
            >
              hotel (ID - {room.hotelId})
            </Link>
          )}
          {instanceToken.role === "hotel" && (
            <Link underline="hover" color="inherit" to={`/rooms`}>
              rooms
            </Link>
          )}
          <Typography color="text.primary">room (ID - {id})</Typography>
        </Breadcrumbs>
      </div>
      {showAlert.message ? (
        <em className="placeholder">
          It will be automatic navigated to hotel (ID - {room.hotelId}) page
          soon.
        </em>
      ) : (
        <Container
          maxWidth={false}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            mt: 2,
          }}
        >
          <Card
            sx={{
              minWidth: "70vw",
              display: "grid",
              gridTemplateColumns: "2fr 3fr",
            }}
          >
            <Box>
              {room.pictures && (
                <ImageList
                  sx={{ width: 500, height: 250, mx: 2 }}
                  cols={3}
                  rowHeight={164}
                >
                  {room.pictures.split("||").map((img, index) => (
                    <ImageListItem key={index}>
                      <img
                        src={`${img}?w=164&h=164&fit=crop&auto=format`}
                        srcSet={`${img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                        alt=""
                        loading="lazy"
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
              )}
              <CardContent sx={{ pr: 5 }}>
                <Grid sx={{ m: 2 }} container spacing={1}>
                  <Grid item xs={3}>
                    <Typography variant="body2" color="text.secondary">
                      Name
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="body2" color="text.secondary">
                      {room.name}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid sx={{ m: 2 }} container spacing={1}>
                  <Grid item xs={3}>
                    <Typography variant="body2" color="text.secondary">
                      Name (Myanmar)
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="body2" color="text.secondary">
                      {room.nameMm}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid sx={{ m: 2 }} container spacing={1}>
                  <Grid item xs={3}>
                    <Typography variant="body2" color="text.secondary">
                      Number of person
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="body2" color="text.secondary">
                      {room.noOfPerson}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid sx={{ m: 2 }} container spacing={1}>
                  <Grid item xs={3}>
                    <Typography variant="body2" color="text.secondary">
                      Price
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="body2" color="text.secondary">
                      {room.price}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid sx={{ m: 2 }} container spacing={1}>
                  <Grid item xs={3}>
                    <Typography variant="body2" color="text.secondary">
                      Created On
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="body2" color="text.secondary">
                      {room.createdAt.substring(0, 10)}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Box>
            <Box>
              <Grid sx={{ m: 2, width: "95%" }} container spacing={1}>
                <Grid item xs={3}>
                  <Typography variant="body2" color="text.secondary">
                    Bedding
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2" color="text.secondary">
                    {room.bedding}
                  </Typography>
                </Grid>
              </Grid>
              <Grid sx={{ m: 2, width: "95%" }} container spacing={1}>
                <Grid item xs={3}>
                  <Typography variant="body2" color="text.secondary">
                    Bedding (Myanmar)
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2" color="text.secondary">
                    {room.beddingMm}
                  </Typography>
                </Grid>
              </Grid>
              <Grid sx={{ m: 2, width: "95%" }} container spacing={1}>
                <Grid item xs={3}>
                  <Typography variant="body2" color="text.secondary">
                    Food And Beverage
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2" color="text.secondary">
                    {room.foodAndBeverage}
                  </Typography>
                </Grid>
              </Grid>
              <Grid sx={{ m: 2, width: "95%" }} container spacing={1}>
                <Grid item xs={3}>
                  <Typography variant="body2" color="text.secondary">
                    Food And Beverage (Myanmar)
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2" color="text.secondary">
                    {room.foodAndBeverageMm}
                  </Typography>
                </Grid>
              </Grid>
              <Grid sx={{ m: 2, width: "95%" }} container spacing={1}>
                <Grid item xs={3}>
                  <Typography variant="body2" color="text.secondary">
                    Bathroom
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2" color="text.secondary">
                    {room.bathroom}
                  </Typography>
                </Grid>
              </Grid>
              <Grid sx={{ m: 2, width: "95%" }} container spacing={1}>
                <Grid item xs={3}>
                  <Typography variant="body2" color="text.secondary">
                    Bathroom (Myanmar)
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2" color="text.secondary">
                    {room.bathroomMm}
                  </Typography>
                </Grid>
              </Grid>
              <Grid sx={{ m: 2, width: "95%" }} container spacing={1}>
                <Grid item xs={3}>
                  <Typography variant="body2" color="text.secondary">
                    Description
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2" color="text.secondary">
                    {room.description}
                  </Typography>
                </Grid>
              </Grid>
              <Grid sx={{ m: 2, width: "95%" }} container spacing={1}>
                <Grid item xs={3}>
                  <Typography variant="body2" color="text.secondary">
                    Description (Myanmar)
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2" color="text.secondary">
                    {room.descriptionMm}
                  </Typography>
                </Grid>
              </Grid>
              <Grid sx={{ m: 2, width: "95%" }} container spacing={1}>
                <Grid item xs={3}>
                  <Typography variant="body2" color="text.secondary">
                    Room Service
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2" color="text.secondary">
                    {room.roomService}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <CardActions sx={{ gridColumn: "1/3", justifyContent: "end" }}>
              <Button size="small" color="error" onClick={handleOpenR}>
                Remove
              </Button>
              <Button
                size="small"
                component={Link}
                to={`/updateRoom/${room.id}`}
              >
                Edit
              </Button>
            </CardActions>
          </Card>
        </Container>
      )}

      <Modal
        keepMounted
        open={openR}
        onClose={handleCloseR}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={styleR}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Confirmation
          </Typography>
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
            Are you sure want to remove it?
          </Typography>
          <Box sx={{ textAlign: "right", mt: 2 }}>
            <Button color="secondary" onClick={handleCloseR}>
              Cancel
            </Button>
            <Button onClick={() => handleRemove()}>Confirm</Button>
          </Box>
        </Box>
      </Modal>
      {showAlert.message && !showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="success"
        >
          {showAlert.message}
        </Alert>
      )}
      {showAlert.message && showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="warning"
        >
          {showAlert.message}
        </Alert>
      )}
    </>
  );
};

export default Room;
