import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import restaurantService from "../../../services/restaurant";
import menuService from "../../../services/menu";
import uploadService from "../../../services/upload";
import {
  Container,
  Card,
  Grid,
  Box,
  Typography,
  CardContent,
  CardActions,
  Button,
  Breadcrumbs,
  Modal,
  Alert,
  ImageList,
  ImageListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Avatar,
  ButtonGroup,
} from "@mui/material";
import { instanceToken } from "../../../utils/constant";

const styleR = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Restaurant = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [openR, setOpenR] = useState(false);
  const [openMR, setOpenMR] = useState(false);
  const [restaurant, setRestaurant] = useState(null);
  const [showAlert, setShowAlert] = useState({ message: "", isError: false });
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [offset, setOffset] = useState(0);
  const [menus, setMenus] = useState(null);
  const [menu, setMenu] = useState(null);
  const [go, setGo] = useState(false);

  useEffect(() => {
    if (!restaurant) {
      fetchRestaurant();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (restaurant) {
      fetchMenus(restaurant.id, rowsPerPage, offset);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, rowsPerPage, restaurant]);

  const fetchMenus = async (id, limit, offset) => {
    try {
      const res = await menuService.getMenus(id, limit, offset);
      parse(res.data);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };

  const parse = (data) => {
    setMenus(data.menus);
    setCount(data.count);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setOffset(rowsPerPage * newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const fetchRestaurant = async () => {
    try {
      const res = await restaurantService.getRestaurant(id);
      setRestaurant(res.data);
    } catch (error) {
      console.error("error : ", error);
      throw new Error(error);
    }
  };

  if (!restaurant) {
    return <em className="placeholder">Loading...</em>;
  }

  const handleOpenR = () => {
    setOpenR(true);
  };
  const handleCloseR = () => {
    setOpenR(false);
  };
  const handleOpenMR = (data) => {
    setMenu(data);
    setOpenMR(true);
  };
  const handleCloseMR = () => {
    setMenu(null);
    setOpenMR(false);
  };

  const deleteImage = async (fileName) => {
    try {
      await uploadService.deleteImage(instanceToken.token, {
        imageName: fileName,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleRemove = async () => {
    try {
      if (menus.length > 0) {
        setShowAlert({
          message: "Please remove all of the menu first.",
          isError: true,
        });
        setTimeout(() => {
          setShowAlert({ message: "", isError: false });
        }, 4000);
      } else {
        if (restaurant.pictures) {
          restaurant.pictures.split("||").forEach(async (picture) => {
            let image_name = picture.substring(
              picture.lastIndexOf("/") + 1,
              picture.length
            );
            await deleteImage(image_name);
          });
        }
        await restaurantService.deleteRestaurant(
          instanceToken.token,
          restaurant.id
        );
        setGo(true);
        setShowAlert({
          message: "Restaurant have been removed.",
          isError: false,
        });
        setTimeout(() => {
          setGo(false);
          setShowAlert({ message: "", isError: false });
          navigate("/restaurants");
        }, 4000);
      }
    } catch (error) {
      console.log(error);
      setShowAlert({
        message: "Error on server!",
        isError: true,
      });
      setTimeout(() => {
        setShowAlert({ message: "", isError: false });
      }, 4000);
    } finally {
      handleCloseR();
    }
  };

  const handleRemoveMenu = async () => {
    try {
      if (menu.picture) {
        await uploadService.deleteImage(instanceToken.token, {
          imageName: menu.picture,
        });
      }
      await menuService.deleteMenu(instanceToken.token, menu.id);
      setMenus(menus.filter((m) => m.id !== menu.id));
      setShowAlert({
        message: "Menu have been removed.",
        isError: false,
      });
    } catch (error) {
      console.log(error);
      setShowAlert({
        message: "Error on server!",
        isError: true,
      });
    } finally {
      setMenu(null);
      handleCloseMR();
      setTimeout(() => {
        setShowAlert({ message: "", isError: false });
      }, 4000);
    }
  };

  return (
    <>
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" to="/restaurants">
            restaurants
          </Link>
          <Typography color="text.primary">restaurant (ID - {id})</Typography>
        </Breadcrumbs>
      </div>
      {go ? (
        <em className="placeholder">
          It will be automatic navigated to restaurants page soon.
        </em>
      ) : (
        <>
          <Container
            maxWidth={false}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              mt: 2,
            }}
          >
            <Card
              sx={{
                display: "grid",
                gridTemplateColumns: "2fr 3fr",
              }}
            >
              <Box>
                {restaurant.pictures && (
                  <ImageList
                    sx={{ width: 500, height: 250, mx: 2 }}
                    cols={3}
                    rowHeight={164}
                  >
                    {restaurant.pictures.split("||").map((img, index) => (
                      <ImageListItem key={index}>
                        <img
                          src={`${img}?w=164&h=164&fit=crop&auto=format`}
                          srcSet={`${img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                          alt=""
                          loading="lazy"
                        />
                      </ImageListItem>
                    ))}
                  </ImageList>
                )}
                <CardContent sx={{ pr: 5 }}>
                  <Grid sx={{ m: 2 }} container spacing={1}>
                    <Grid item xs={3}>
                      <Typography variant="body2" color="text.secondary">
                        Name
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant="body2" color="text.secondary">
                        {restaurant.name}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid sx={{ m: 2 }} container spacing={1}>
                    <Grid item xs={3}>
                      <Typography variant="body2" color="text.secondary">
                        Name (Myanmar)
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant="body2" color="text.secondary">
                        {restaurant.nameMm}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid sx={{ m: 2 }} container spacing={1}>
                    <Grid item xs={3}>
                      <Typography variant="body2" color="text.secondary">
                        City
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant="body2" color="text.secondary">
                        {restaurant.city?.name}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid sx={{ m: 2 }} container spacing={1}>
                    <Grid item xs={3}>
                      <Typography variant="body2" color="text.secondary">
                        Latitude
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant="body2" color="text.secondary">
                        {restaurant.location?.coordinates[0]}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid sx={{ m: 2 }} container spacing={1}>
                    <Grid item xs={3}>
                      <Typography variant="body2" color="text.secondary">
                        Longitude
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant="body2" color="text.secondary">
                        {restaurant.location?.coordinates[1]}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid sx={{ m: 2 }} container spacing={1}>
                    <Grid item xs={3}>
                      <Typography variant="body2" color="text.secondary">
                        Created On
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant="body2" color="text.secondary">
                        {restaurant.createdAt.substring(0, 10)}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Box>
              <Box>
                <Grid sx={{ m: 2, width: "95%" }} container spacing={1}>
                  <Grid item xs={3}>
                    <Typography variant="body2" color="text.secondary">
                      Stars
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="body2" color="text.secondary">
                      {restaurant.stars}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid sx={{ m: 2, width: "95%" }} container spacing={1}>
                  <Grid item xs={3}>
                    <Typography variant="body2" color="text.secondary">
                      Opening Time
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="body2" color="text.secondary">
                      {restaurant.open}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid sx={{ m: 2, width: "95%" }} container spacing={1}>
                  <Grid item xs={3}>
                    <Typography variant="body2" color="text.secondary">
                      Phone Numbers
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="body2" color="text.secondary">
                      {restaurant.phones}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid sx={{ m: 2, width: "95%" }} container spacing={1}>
                  <Grid item xs={3}>
                    <Typography variant="body2" color="text.secondary">
                      Address
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="body2" color="text.secondary">
                      {restaurant.address}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid sx={{ m: 2, width: "95%" }} container spacing={1}>
                  <Grid item xs={3}>
                    <Typography variant="body2" color="text.secondary">
                      Address (Myanmar)
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="body2" color="text.secondary">
                      {restaurant.addressMm}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid sx={{ m: 2, width: "95%" }} container spacing={1}>
                  <Grid item xs={3}>
                    <Typography variant="body2" color="text.secondary">
                      Description
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="body2" color="text.secondary">
                      {restaurant.description}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid sx={{ m: 2, width: "95%" }} container spacing={1}>
                  <Grid item xs={3}>
                    <Typography variant="body2" color="text.secondary">
                      Description (Myanmar)
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="body2" color="text.secondary">
                      {restaurant.descriptionMm}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <CardActions sx={{ gridColumn: "1/3", justifyContent: "end" }}>
                <Button size="small" color="error" onClick={handleOpenR}>
                  Remove
                </Button>
                <Button
                  size="small"
                  component={Link}
                  to={`/updaterestaurant/${restaurant.id}`}
                >
                  Edit
                </Button>
              </CardActions>
            </Card>
          </Container>
          <Button
            sx={{ my: 3, mx: 4 }}
            variant="contained"
            component={Link}
            to={`/createMenu/${restaurant.id}`}
          >
            Create Menu
          </Button>
          {!menus ? (
            <em className="subPlaceholder">Loading...</em>
          ) : (
            <TableContainer component={Paper} sx={{ mx: 3, width: "auto" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Image</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell>Created On</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {menus.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <Avatar alt="Remy Sharp" src={row.picture}>
                          M
                        </Avatar>
                      </TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.price}</TableCell>
                      <TableCell>{row.category}</TableCell>
                      <TableCell>{row.createdAt?.substring(0, 10)}</TableCell>
                      <TableCell>
                        <ButtonGroup
                          variant="outlined"
                          aria-label="outlined button group"
                        >
                          <Button
                            size="small"
                            onClick={() => navigate(`/updateMenu/${row.id}`)}
                          >
                            Edit
                          </Button>
                          <Button
                            color="error"
                            size="small"
                            onClick={() => handleOpenMR(row)}
                          >
                            Remove
                          </Button>
                        </ButtonGroup>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          )}
        </>
      )}

      <Modal
        keepMounted
        open={openR}
        onClose={handleCloseR}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={styleR}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Confirmation
          </Typography>
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
            Are you sure want to remove it?
          </Typography>
          <Box sx={{ textAlign: "right", mt: 2 }}>
            <Button color="secondary" onClick={handleCloseR}>
              Cancel
            </Button>
            <Button onClick={() => handleRemove()}>Confirm</Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        keepMounted
        open={openMR}
        onClose={handleCloseMR}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={styleR}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Confirmation
          </Typography>
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
            Are you sure want to remove menu?
          </Typography>
          <Box sx={{ textAlign: "right", mt: 2 }}>
            <Button color="secondary" onClick={handleCloseMR}>
              Cancel
            </Button>
            <Button onClick={() => handleRemoveMenu()}>Confirm</Button>
          </Box>
        </Box>
      </Modal>
      {showAlert.message && !showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="success"
        >
          {showAlert.message}
        </Alert>
      )}
      {showAlert.message && showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="warning"
        >
          {showAlert.message}
        </Alert>
      )}
    </>
  );
};

export default Restaurant;
