import React, { useState, useEffect } from "react";
import placeService from "../../../services/place";
import uploadService from "../../../services/upload";
import cityService from "../../../services/city";

import {
  Card,
  CardContent,
  CardActions,
  Box,
  Typography,
  FormControl,
  TextField,
  Alert,
  Breadcrumbs,
  ImageList,
  ImageListItem,
  Button,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { LoadingButton } from "@mui/lab";
import { instanceToken } from "../../../utils/constant";
import { Link, useParams } from "react-router-dom";

const imgFileTypes = ["image/gif", "image/jpeg", "image/png", "image/svg+xml"];

export default function UpdatePlace() {
  const { id } = useParams();
  const [cities, setCities] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imageFiles, setImageFiles] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [isImageChange, setIsImageChange] = useState(false);
  const [showAlert, setShowAlert] = useState({ message: "", isError: false });
  const [values, setValues] = useState({
    name: "",
    description: "",
    recommandation: "",
    time: "",
    address: "",
    general: "",
    health: "",
    safety: "",
    cityId: "",
    lat: "",
    long: "",
    stars: "",
    nameMm: "",
    descriptionMm: "",
    recommandationMm: "",
    addressMm: "",
    generalMm: "",
    healthMm: "",
    safetyMm: "",
  });
  const [errors, setErrors] = useState({});

  const [place, setPlace] = useState(null);
  const [oldImageNames, setOldImageNames] = useState([]);

  useEffect(() => {
    if (!place) {
      fetchPlace();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (place) {
      setValues({
        name: place.name ?? "",
        description: place.description ?? "",
        recommandation: place.description ?? "",
        address: place.address ?? "",
        general: place.general ?? "",
        health: place.health ?? "",
        safety: place.safety ?? "",
        cityId: place.cityId ?? "",
        time: place.time ?? "",
        stars: place.stars ?? "",
        lat: place.location?.coordinates[0] ?? "",
        long: place.location?.coordinates[1] ?? "",
        nameMm: place.nameMm ?? "",
        descriptionMm: place.descriptionMm ?? "",
        recommandationMm: place.descriptionMm ?? "",
        addressMm: place.addressMm ?? "",
        generalMm: place.generalMm ?? "",
        healthMm: place.healthMm ?? "",
        safetyMm: place.safetyMm ?? "",
      });
      let image_urls = place.pictures ? place.pictures.split("||") : [];
      setPreviews(image_urls);
      if (image_urls) {
        const image_names = [];
        for (let image_url of image_urls) {
          image_names.push(
            image_url.substring(
              image_url.lastIndexOf("/") + 1,
              image_url.length
            )
          );
        }
        setOldImageNames(image_names);
      }
    }
  }, [place]);

  const fetchPlace = async () => {
    try {
      const res = await placeService.getPlace(id);
      setPlace(res.data);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };

  useEffect(() => {
    fetchCities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCities = async () => {
    try {
      const res = await cityService.getCities();
      setCities(res.data.cities);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };

  if (!cities || !place) {
    return <em className="placeholder">Loading...</em>;
  }

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const imgFileSelect = async (e) => {
    if (e.target.files && e.target.files[0]) {
      const fileList = e.target.files;
      const files = [];
      const preview = [];
      for (let i = 0, numFiles = fileList.length; i < numFiles; i++) {
        const img = fileList[i];
        if (!imgFileTypes.includes(img.type)) {
          setErrors({
            ...errors,
            profile: "Please select image. (PNG, JPG, JPEG, GIF, ...)",
          });
          return;
        }
        if (img.size > 10485760) {
          setErrors({
            ...errors,
            profile: "Image file size must be smaller than 10MB.",
          });
          return;
        }
        setIsImageChange(true);
        files.push(img);
        preview.push(URL.createObjectURL(img));
      }
      setPreviews(preview);
      setImageFiles(files);
    }
  };

  const handleImgUpload = async (url, imageFile) => {
    try {
      await uploadService.uploadImage(url, imageFile);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchImageUrl = async () => {
    try {
      const res = await uploadService.getImageUrl(instanceToken.token);
      if (res.data) {
        return {
          url: res.data.imageUploadUrl,
          name: `https://axra.sgp1.digitaloceanspaces.com/GoWithAx/${res.data.imageName}`,
        };
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteImage = async (fileName) => {
    try {
      await uploadService.deleteImage(instanceToken.token, {
        imageName: fileName,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdate = async () => {
    setErrors({});
    let err = {};
    if (!values.name) {
      err.name = "Name field is required";
    }
    if (!values.description) {
      err.description = "Description field is required";
    }
    if (!values.stars) {
      err.stars = "Stars field is required";
    }
    if (isNaN(values.stars)) {
      err.stars = "Stars field must be number";
    }
    if (!values.recommandation) {
      err.recommandation = "Recommandation field is required";
    }
    if (!values.time) {
      err.time = "Opening time field is required";
    }
    if (!values.address) {
      err.address = "Address field is required";
    }
    if (!values.cityId) {
      err.cityId = "City field is required";
    }
    if (values.lat && isNaN(values.lat)) {
      err.lat = "Latitude field must be number";
    }
    if (values.long && isNaN(values.long)) {
      err.long = "Longitude field must be number";
    }
    if ((values.lat && !values.long) || (values.long && !values.lat)) {
      err.lat = "Please enter both Latitude and Longitude";
      err.long = "Please enter both Latitude and Longitude";
    }
    if (!values.nameMm) {
      err.nameMm = "Name (Myanmar) field is required";
    }
    if (!values.descriptionMm) {
      err.descriptionMm = "Description (Myanmar) field is required";
    }
    if (!values.recommandationMm) {
      err.recommandationMm = "Recommandation (Myanmar) field is required";
    }
    if (!values.addressMm) {
      err.addressMm = "Address (Myanmar) field is required";
    }
    if (Object.getOwnPropertyNames(err).length > 0) {
      setErrors({ ...err });
      return;
    }
    try {
      setLoading(true);
      let data = values;
      if (isImageChange) {
        if (oldImageNames.length > 0) {
          for (const oldImageName of oldImageNames) {
            await deleteImage(oldImageName);
          }
        }
        const fileNames = [];
        for (let i = 0, numFiles = imageFiles.length; i < numFiles; i++) {
          const img = imageFiles[i];
          const { url, name } = await fetchImageUrl();
          await handleImgUpload(url, img);
          fileNames.push(name);
        }
        const names = fileNames.join("||");
        data = { ...data, pictures: names };
      } else {
        data = { ...data, pictures: place.pictures };
      }

      let response = await placeService.putPlace(
        instanceToken.token,
        place.id,
        data
      );
      setPlace(response.data);
      setImageFiles([]);
      setIsImageChange(false);
      setShowAlert({
        message: "Place have been updated.",
        isError: false,
      });
    } catch (error) {
      console.log(error);
      setShowAlert({
        message: "Error on server!",
        isError: true,
      });
    } finally {
      setLoading(false);
      setTimeout(() => {
        setShowAlert({ message: "", isError: false });
      }, 4000);
    }
  };

  return (
    <>
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" to="/places">
            places
          </Link>
          <Link underline="hover" color="inherit" to={`/place/${id}`}>
            place (ID - {id})
          </Link>
          <Typography color="text.primary">update place</Typography>
        </Breadcrumbs>
      </div>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <Card sx={{ minWidth: 300, height: "auto" }}>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                mt: 2,
                height: "auto",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <FormControl
                  sx={{ m: 2, mt: 0, width: 500 }}
                  variant="outlined"
                >
                  <TextField
                    id="name"
                    label="Name"
                    value={values.name}
                    onChange={handleChange("name")}
                    error={errors.name ? true : false}
                    helperText={errors.name}
                  />
                </FormControl>
                <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                  <TextField
                    id="name_mm"
                    label="Name (Myanmar)"
                    value={values.nameMm}
                    onChange={handleChange("nameMm")}
                    error={errors.nameMm ? true : false}
                    helperText={errors.nameMm}
                  />
                </FormControl>
                <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                  <TextField
                    id="stars"
                    label="Stars"
                    value={values.stars}
                    onChange={handleChange("stars")}
                    error={errors.stars ? true : false}
                    helperText={errors.stars}
                  />
                </FormControl>
                <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                  <TextField
                    id="time"
                    label="Opening time"
                    value={values.time}
                    onChange={handleChange("time")}
                    error={errors.time ? true : false}
                    helperText={errors.time}
                  />
                </FormControl>
                <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                  <InputLabel id="cityId">Select city</InputLabel>
                  <Select
                    labelId="cityId"
                    value={values.cityId}
                    label="Select city"
                    onChange={handleChange("cityId")}
                    error={errors.cityId ? true : false}
                  >
                    <MenuItem value=""></MenuItem>
                    {cities.map((c, index) => (
                      <MenuItem key={index} value={c.id}>
                        {c.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.cityId && (
                    <FormHelperText error>{errors.cityId}</FormHelperText>
                  )}
                </FormControl>
                <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                  <TextField
                    id="lat"
                    label="Latitude"
                    value={values.lat}
                    onChange={handleChange("lat")}
                    error={errors.lat ? true : false}
                    helperText={errors.lat}
                  />
                </FormControl>
                <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                  <TextField
                    id="long"
                    label="Longitude"
                    value={values.long}
                    onChange={handleChange("long")}
                    error={errors.long ? true : false}
                    helperText={errors.long}
                  />
                </FormControl>
                <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                  <TextField
                    id="address"
                    label="Address"
                    multiline
                    value={values.address}
                    onChange={handleChange("address")}
                    error={errors.address ? true : false}
                    helperText={errors.address}
                    rows={2}
                  />
                </FormControl>
                <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                  <TextField
                    id="address_mm"
                    label="Address (Myanmar)"
                    multiline
                    value={values.addressMm}
                    onChange={handleChange("addressMm")}
                    error={errors.addressMm ? true : false}
                    helperText={errors.addressMm}
                    rows={2}
                  />
                </FormControl>
                <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                  <TextField
                    id="description"
                    label="Description"
                    multiline
                    value={values.description}
                    onChange={handleChange("description")}
                    error={errors.description ? true : false}
                    helperText={errors.description}
                    rows={6}
                  />
                </FormControl>
                <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                  <TextField
                    id="description_mm"
                    label="Description (Myanmar)"
                    multiline
                    value={values.descriptionMm}
                    onChange={handleChange("descriptionMm")}
                    error={errors.descriptionMm ? true : false}
                    helperText={errors.descriptionMm}
                    rows={6}
                  />
                </FormControl>
                <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                  <Button
                    variant="contained"
                    component="label"
                    size="large"
                    sx={{ p: 2 }}
                  >
                    <PhotoCamera />
                    {errors.pictures ? (
                      <Typography sx={{ ml: 1, color: "red" }}>
                        {errors.pictures}
                      </Typography>
                    ) : (
                      <Typography sx={{ ml: 1 }}>Upload Pictures</Typography>
                    )}
                    <input
                      hidden
                      onChange={imgFileSelect}
                      accept={imgFileTypes}
                      multiple
                      type="file"
                    />
                  </Button>
                </FormControl>
                <ImageList
                  sx={{ width: 500, height: "auto", m: 2 }}
                  cols={3}
                  rowHeight={154}
                >
                  {previews.length > 0 &&
                    previews.map((img, index) => (
                      <ImageListItem sx={{ m: 0, p: 0 }} key={index}>
                        <img src={img} alt="" loading="lazy" />
                      </ImageListItem>
                    ))}
                </ImageList>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  p: 0,
                  m: 0,
                }}
              >
                <FormControl
                  sx={{ m: 2, mt: 0, width: 500 }}
                  variant="outlined"
                >
                  <TextField
                    id="recommandation"
                    label="Recommandation"
                    multiline
                    value={values.recommandation}
                    onChange={handleChange("recommandation")}
                    error={errors.recommandation ? true : false}
                    helperText={errors.recommandation}
                    rows={6}
                  />
                </FormControl>
                <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                  <TextField
                    id="recommandation_mm"
                    label="Recommandation (Myanmar)"
                    multiline
                    value={values.recommandationMm}
                    onChange={handleChange("recommandationMm")}
                    error={errors.recommandationMm ? true : false}
                    helperText={errors.recommandationMm}
                    rows={6}
                  />
                </FormControl>
                <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                  <TextField
                    id="general"
                    label="General"
                    multiline
                    value={values.general}
                    onChange={handleChange("general")}
                    error={errors.general ? true : false}
                    helperText={errors.general}
                    rows={6}
                  />
                </FormControl>
                <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                  <TextField
                    id="general_mm"
                    label="General (Myanmar)"
                    multiline
                    value={values.generalMm}
                    onChange={handleChange("generalMm")}
                    error={errors.generalMm ? true : false}
                    helperText={errors.generalMm}
                    rows={6}
                  />
                </FormControl>
                <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                  <TextField
                    id="health"
                    label="Health"
                    multiline
                    value={values.health}
                    onChange={handleChange("health")}
                    error={errors.health ? true : false}
                    helperText={errors.health}
                    rows={6}
                  />
                </FormControl>
                <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                  <TextField
                    id="health_mm"
                    label="Health (Myanmar)"
                    multiline
                    value={values.healthMm}
                    onChange={handleChange("healthMm")}
                    error={errors.healthMm ? true : false}
                    helperText={errors.healthMm}
                    rows={6}
                  />
                </FormControl>
                <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                  <TextField
                    id="safety"
                    label="Safety"
                    multiline
                    value={values.safety}
                    onChange={handleChange("safety")}
                    error={errors.safety ? true : false}
                    helperText={errors.safety}
                    rows={6}
                  />
                </FormControl>
                <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                  <TextField
                    id="safety_mm"
                    label="Safety (Myanmar)"
                    multiline
                    value={values.safetyMm}
                    onChange={handleChange("safetyMm")}
                    error={errors.safetyMm ? true : false}
                    helperText={errors.safetyMm}
                    rows={6}
                  />
                </FormControl>
              </Box>
            </Box>
          </CardContent>
          <CardActions sx={{ justifyContent: "end" }}>
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={handleUpdate}
              sx={{ backgroundColor: "#4b26d1", alignSelf: "end" }}
            >
              Update
            </LoadingButton>
          </CardActions>
        </Card>
      </Box>
      {showAlert.message && !showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="success"
        >
          {showAlert.message}
        </Alert>
      )}
      {showAlert.message && showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="warning"
        >
          {showAlert.message}
        </Alert>
      )}
    </>
  );
}
