import axios from "axios";
import { BACKEND_URL } from "../utils/constant";

const getRooms = async (id, limit, offset) => {
  const response = await axios.get(
    `${BACKEND_URL}/api/rooms/${id}?limit=${limit}&offset=${offset}`
  );
  return response;
};

const getRoom = async (id) => {
  const response = await axios.get(`${BACKEND_URL}/api/rooms/one/${id}`);
  return response;
};

const postRoom = async (token, data) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.post(`${BACKEND_URL}/api/rooms`, data, config);
  return response;
};

const deleteRoom = async (token, id) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.delete(`${BACKEND_URL}/api/rooms/${id}`, config);
  return response;
};

const putRoom = async (token, id, data) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.put(
    `${BACKEND_URL}/api/rooms/${id}`,
    data,
    config
  );
  return response;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getRooms,
  postRoom,
  getRoom,
  deleteRoom,
  putRoom,
};
