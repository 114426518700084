import axios from "axios";
import { BACKEND_URL } from "../utils/constant";

const getVisitors = async (token, date) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.get(`${BACKEND_URL}/api/visitors`, config);
  return response;
};

const getSixMonthsVisitors = async (token, date) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.get(
    `${BACKEND_URL}/api/visitors/6m_counts?start_date=${date}`,
    config
  );
  return response;
};

const getTop3Blogs = async (token, date) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.get(
    `${BACKEND_URL}/api/visitors/top3blogs`,
    config
  );
  return response;
};

const getTop3Places = async (token, date) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.get(
    `${BACKEND_URL}/api/visitors/top3places`,
    config
  );
  return response;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getSixMonthsVisitors,
  getVisitors,
  getTop3Blogs,
  getTop3Places,
};
