import axios from "axios";
import { BACKEND_URL } from "../utils/constant";

const getMenus = async (id, limit, offset) => {
  const response = await axios.get(
    `${BACKEND_URL}/api/menus/${id}?limit=${limit}&offset=${offset}`
  );
  return response;
};

const getMenu = async (id) => {
  const response = await axios.get(`${BACKEND_URL}/api/menus/one/${id}`);
  return response;
};

const postMenu = async (token, data) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.post(`${BACKEND_URL}/api/menus`, data, config);
  return response;
};

const deleteMenu = async (token, id) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.delete(`${BACKEND_URL}/api/menus/${id}`, config);
  return response;
};

const putMenu = async (token, id, data) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.put(
    `${BACKEND_URL}/api/menus/${id}`,
    data,
    config
  );
  return response;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getMenus,
  postMenu,
  deleteMenu,
  getMenu,
  putMenu,
};
