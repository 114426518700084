import React, { useState, useEffect } from "react";
import menuService from "../../../services/menu";
import uploadService from "../../../services/upload";
import restaurantService from "../../../services/restaurant";
import {
  Card,
  CardContent,
  CardActions,
  Box,
  Typography,
  FormControl,
  TextField,
  CardMedia,
  Alert,
  Breadcrumbs,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { instanceToken } from "../../../utils/constant";
import { Link, useParams } from "react-router-dom";

const imgFileTypes = ["image/gif", "image/jpeg", "image/png", "image/svg+xml"];

export default function CreateMenu() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [isImageChange, setIsImageChange] = useState(false);
  const [showAlert, setShowAlert] = useState({ message: "", isError: false });
  const [values, setValues] = useState({
    name: "",
    price: "",
    category: "",
  });
  const [errors, setErrors] = useState({});
  const [restaurant, setRestaurant] = useState(null);
  const [placeholder, setPlaceholder] = useState("");

  useEffect(() => {
    if (!restaurant && instanceToken.token !== "master") {
      fetchRestaurant(instanceToken.token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchRestaurant = async (token) => {
    try {
      const res = await restaurantService.getRestaurantByAdminToken(token);
      setRestaurant(res.data);
    } catch (error) {
      if (error.response.status === 401) {
        setPlaceholder("Unauthorized");
      } else {
        throw new Error(error);
      }
    }
  };

  if (instanceToken.role !== "master" && !restaurant) {
    return <em className="placeholder">{placeholder ?? "Loading..."}</em>;
  }

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const imgFileSelect = async (e) => {
    if (e.target.files && e.target.files[0]) {
      const img = e.target.files[0];
      if (!imgFileTypes.includes(img.type)) {
        setErrors({
          ...errors,
          profile: "Please select image. (PNG, JPG, JPEG, GIF, ...)",
        });
        return;
      }
      if (img.size > 10485760) {
        setErrors({
          ...errors,
          profile: "Image file size must be smaller than 10MB.",
        });
        return;
      }
      setPreview(URL.createObjectURL(img));
      setImageFile(img);
      setIsImageChange(true);
    }
  };

  const handleImgUpload = async (url, imageFile) => {
    try {
      await uploadService.uploadImage(url, imageFile);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchImageUrl = async () => {
    try {
      const res = await uploadService.getImageUrl(instanceToken.token);
      if (res.data) {
        return {
          url: res.data.imageUploadUrl,
          name: `https://axra.sgp1.digitaloceanspaces.com/GoWithAx/${res.data.imageName}`,
        };
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCreate = async () => {
    setErrors({});
    let err = {};
    if (!values.name) {
      err.name = "Name field is required";
    }
    if (!values.price) {
      err.price = "Price field is required";
    }
    if (!values.category) {
      err.category = "Category field is required";
    }
    if (Object.getOwnPropertyNames(err).length > 0) {
      setErrors({ ...err });
      return;
    }
    try {
      setLoading(true);
      let data = { ...values };

      if (isImageChange) {
        const img = imageFile;
        const { url, name } = await fetchImageUrl();
        await handleImgUpload(url, img);
        data = { ...data, picture: name };
      }

      await menuService.postMenu(instanceToken.token, {
        ...data,
        restaurantId: id,
      });

      setValues({
        name: "",
        price: "",
        category: "",
      });
      setPreview(null);
      setImageFile(null);
      setIsImageChange(false);
      setShowAlert({
        message: "Menu have been created.",
        isError: false,
      });
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        setShowAlert({
          message: "Unauthorized",
          isError: true,
        });
      } else {
        setShowAlert({
          message: "Error on server!",
          isError: true,
        });
      }
    } finally {
      setLoading(false);
      setTimeout(() => {
        setShowAlert({ message: "", isError: false });
      }, 4000);
    }
  };

  return (
    <>
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb">
          {instanceToken.role === "master" && (
            <Link underline="hover" color="inherit" to={`/restaurant/${id}`}>
              restaurant (ID - {id})
            </Link>
          )}
          {instanceToken.role === "restaurant" && (
            <Link underline="hover" color="inherit" to={`/menus`}>
              Menus
            </Link>
          )}
          <Typography color="text.primary">create menu</Typography>
        </Breadcrumbs>
      </div>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <Card sx={{ minWidth: 300, height: "auto" }}>
          <CardMedia component="img" height="294" image={preview} />
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 0,
                m: 0,
                flex: 2,
              }}
            >
              <FormControl sx={{ m: 2, mt: 0, width: 500 }} variant="outlined">
                <TextField
                  id="name"
                  label="Name"
                  value={values.name}
                  onChange={handleChange("name")}
                  error={errors.name ? true : false}
                  helperText={errors.name}
                />
              </FormControl>
              <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                <TextField
                  id="price"
                  label="Price"
                  value={values.price}
                  onChange={handleChange("price")}
                  error={errors.price ? true : false}
                  helperText={errors.price}
                />
              </FormControl>
              <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                <InputLabel id="category">Select category</InputLabel>
                <Select
                  labelId="category"
                  value={values.category}
                  label="Select category"
                  onChange={handleChange("category")}
                  error={errors.category ? true : false}
                >
                  <MenuItem value=""></MenuItem>
                  <MenuItem value="main">Main</MenuItem>
                  <MenuItem value="appetizer">Appetizer</MenuItem>
                </Select>
                {errors.category && (
                  <FormHelperText error>{errors.category}</FormHelperText>
                )}
              </FormControl>
              <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                <TextField
                  id="image"
                  placeholder="Upload image"
                  InputLabelProps={{ shrink: true }}
                  label="Upload Image"
                  onChange={imgFileSelect}
                  error={errors.picture ? true : false}
                  helperText={errors.picture}
                  type="file"
                  accept={imgFileTypes}
                />
              </FormControl>
            </Box>
          </CardContent>
          <CardActions sx={{ justifyContent: "end" }}>
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={handleCreate}
              sx={{ backgroundColor: "#4b26d1", alignSelf: "end" }}
            >
              Create
            </LoadingButton>
          </CardActions>
        </Card>
      </Box>
      {showAlert.message && !showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="success"
        >
          {showAlert.message}
        </Alert>
      )}
      {showAlert.message && showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="warning"
        >
          {showAlert.message}
        </Alert>
      )}
    </>
  );
}
