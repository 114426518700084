import axios from "axios";
import { BACKEND_URL } from "../utils/constant";

const getCities = async (limit, offset) => {
  const response = await axios.get(
    `${BACKEND_URL}/api/cities?limit=${limit}&offset=${offset}`
  );
  return response;
};

const getCity = async (id) => {
  const response = await axios.get(`${BACKEND_URL}/api/cities/${id}`);
  return response;
};

const putCity = async (token, id, data) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.put(
    `${BACKEND_URL}/api/cities/${id}`,
    data,
    config
  );
  return response;
};

const postCity = async (token, data) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.post(`${BACKEND_URL}/api/cities`, data, config);
  return response;
};

const deleteCity = async (token, id) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.delete(
    `${BACKEND_URL}/api/cities/${id}`,
    config
  );
  return response;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getCities,
  putCity,
  postCity,
  deleteCity,
  getCity,
};
