import React, {
  useEffect,
  useState,
  createContext,
  Suspense,
  lazy,
  useContext,
} from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Header from "../layouts/Header";
import SideBar from "../layouts/SideBar";
import { styled } from "@mui/material/styles";
import { Route, Routes, useNavigate, Navigate } from "react-router-dom";
import { initToken } from "../utils/constant";
import authService from "../services/auth";
import hotelService from "../services/hotel";
import restaurantService from "../services/restaurant";

import Dashboard from "./auth/Dashboard";
import Blogs from "./auth/blogs/Blogs";
import Blog from "./auth/blogs/Blog";
import Contacts from "./auth/contacts/Contacts";
import CreateBlog from "./auth/blogs/CreateBlog";
import UpdateBlog from "./auth/blogs/UpdateBlog";
import CreateCity from "./auth/cities/CreateCity";
import Cities from "./auth/cities/Cities";
import City from "./auth/cities/City";
import UpdateCity from "./auth/cities/UpdateCity";
import Places from "./auth/places/Places";
import CreatePlace from "./auth/places/CreatePlace";
import Place from "./auth/places/Place";
import UpdatePlace from "./auth/places/UpdatePlace";
import Hotels from "./auth/hotels/Hotels";
import CreateHotel from "./auth/hotels/CreateHotel";
import Hotel from "./auth/hotels/Hotel";
import UpdateHotel from "./auth/hotels/UpdateHotel";
import Restaurants from "./auth/restaurants/Restaurants";
import CreateRestaurant from "./auth/restaurants/CreateRestaurant";
import Restaurant from "./auth/restaurants/Restaurant";
import UpdateRestaurant from "./auth/restaurants/UpdateRestaurant";
import CreateMenu from "./auth/menus/CreateMenu";
import CreateRoom from "./auth/rooms/CreateRoom";
import Room from "./auth/rooms/Room";
const Users = lazy(() => import("./auth/users/Users"));
const FAQs = lazy(() => import("./auth/faqs/FAQs"));
const CreateFAQ = lazy(() => import("./auth/faqs/CreateFAQ"));
const UpdateFAQ = lazy(() => import("./auth/faqs/UpdateFAQ"));
const CustomerMessages = lazy(() =>
  import("./auth/customerMessages/CustomerMessages")
);
const EmergencyNumbers = lazy(() =>
  import("./auth/emergencyNumbers/EmergencyNumbers")
);
const CreateEmergencyNumber = lazy(() =>
  import("./auth/emergencyNumbers/CreateEmergencyNumber")
);
const UpdateEmergencyNumber = lazy(() =>
  import("./auth/emergencyNumbers/UpdateEmergencyNumber")
);
const States = lazy(() => import("./auth/states/States"));
const State = lazy(() => import("./auth/states/State"));
const CreateState = lazy(() => import("./auth/states/CreateState"));
const UpdateState = lazy(() => import("./auth/states/UpdateState"));
const Admins = lazy(() => import("./auth/admins/Admins"));
const CreateAdmin = lazy(() => import("./auth/admins/CreateAdmin"));
const UpdateAdmin = lazy(() => import("./auth/admins/UpdateAdmin"));
const OwnHotel = lazy(() => import("./auth/hotels/OwnHotel"));
const OwnRooms = lazy(() => import("./auth/rooms/OwnRooms"));
const UpdateRoom = lazy(() => import("./auth/rooms/UpdateRoom"));
const OwnRestaurant = lazy(() => import("./auth/restaurants/OwnRestaurant"));
const OwnMenus = lazy(() => import("./auth/menus/OwnMenus"));
const UpdateMenu = lazy(() => import("./auth/menus/UpdateMenu"));
const OwnHotelPromotions = lazy(() =>
  import("./auth/hotelPromotions/OwnPromotions")
);
const CreateHotelPromotion = lazy(() =>
  import("./auth/hotelPromotions/CreatePromotion")
);
const UpdateHotelPromotion = lazy(() =>
  import("./auth/hotelPromotions/UpdatePromotion")
);
const OwnRestaurantPromotions = lazy(() =>
  import("./auth/restaurantPromotions/OwnPromotions")
);
const CreateRestaurantPromotion = lazy(() =>
  import("./auth/restaurantPromotions/CreatePromotion")
);
const UpdateRestaurantPromotion = lazy(() =>
  import("./auth/restaurantPromotions/UpdatePromotion")
);

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const AuthContext = createContext({});
export const HotelContext = createContext({});
export const RestaurantContext = createContext({});

export function useHotel() {
  return useContext(HotelContext);
}

export function useRestaurant() {
  return useContext(RestaurantContext);
}

export default function Home() {
  const navigate = useNavigate();
  const [auth, setAuth] = useState(null);
  const [hotel, setHotel] = useState(null);
  const [restaurant, setRestaurant] = useState(null);
  const [open, setOpen] = useState(false);
  const [placeholder, setPlaceholder] = useState("");

  useEffect(() => {
    const loggedJSON = window.localStorage.getItem("go_with_ax_loggedAdmin");
    if (loggedJSON) {
      const parsedJSON = JSON.parse(loggedJSON);
      fetchAuth(parsedJSON.token).then(() => {
        initToken(parsedJSON.token, parsedJSON.decodedToken?.role);
      });
      if (parsedJSON.decodedToken?.role === "hotel") {
        fetchHotel(parsedJSON.token);
      }
      if (parsedJSON.decodedToken?.role === "restaurant") {
        fetchRestaurant(parsedJSON.token);
      }
    } else {
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAuth = async (token) => {
    try {
      const res = await authService.getAuth(token);
      setAuth(res.data);
    } catch (error) {
      console.log(error);
      if (
        error?.response?.data.error === "token invalid" ||
        error?.response?.data.error === "token expired"
      ) {
        navigate("/login");
      }
    }
  };

  const fetchHotel = async (token) => {
    try {
      const res = await hotelService.getHotelByAdminToken(token);
      setHotel(res.data);
    } catch (error) {
      if (error.response.status === 404) {
        // setPlaceholder("There is no data");
        setHotel(undefined);
      } else if (error.response.status === 401) {
        setPlaceholder("Unauthorized");
      } else {
        throw new Error(error);
      }
    }
  };

  const fetchRestaurant = async (token) => {
    try {
      const res = await restaurantService.getRestaurantByAdminToken(token);
      setRestaurant(res.data);
    } catch (error) {
      if (error.response.status === 404) {
        // setPlaceholder("There is no data");
        setRestaurant(undefined);
      } else if (error.response.status === 401) {
        setPlaceholder("Unauthorized");
      } else {
        throw new Error(error);
      }
    }
  };

  const updateAuth = (data) => {
    setAuth(data);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  if (!auth) {
    return <em className="placeholder">Loading...</em>;
  }

  if (auth.role === "hotel" && hotel === null) {
    return <em className="placeholder">{placeholder ?? "Loading..."}</em>;
  }

  if (auth.role === "restaurant" && restaurant === null) {
    return <em className="placeholder">{placeholder ?? "Loading..."}</em>;
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Header
        open={open}
        handleDrawerOpen={handleDrawerOpen}
        user={auth}
        updateUser={updateAuth}
      />
      <SideBar handleDrawerClose={handleDrawerClose} open={open} auth={auth} />
      <Main open={open}>
        <DrawerHeader />
        <Suspense fallback={<div>Loading...</div>}>
          <AuthContext.Provider value={auth}>
            {auth.role === "master" && (
              <Routes>
                <Route path="/updateAdmin/:id" element={<UpdateAdmin />} />
                <Route
                  path="/dashboard"
                  element={<Dashboard user={auth} updateUser={updateAuth} />}
                />
                <Route path="/blogs" element={<Blogs />} />
                <Route path="/blog/:id" element={<Blog />} />
                <Route path="/createBlog" element={<CreateBlog />} />
                <Route path="/updateBlog/:id" element={<UpdateBlog />} />
                <Route path="/states" element={<States />} />
                <Route path="/state/:id" element={<State />} />
                <Route path="/createState" element={<CreateState />} />
                <Route path="/updateState/:id" element={<UpdateState />} />
                <Route path="/contacts" element={<Contacts />} />
                <Route path="/cities" element={<Cities />} />
                <Route path="/createCity" element={<CreateCity />} />
                <Route path="/city/:id" element={<City />} />
                <Route path="/updateCity/:id" element={<UpdateCity />} />
                <Route path="/places" element={<Places />} />
                <Route path="/createPlace" element={<CreatePlace />} />
                <Route path="/place/:id" element={<Place />} />
                <Route path="/updatePlace/:id" element={<UpdatePlace />} />
                <Route path="/hotels" element={<Hotels />} />
                <Route path="/createHotel" element={<CreateHotel />} />
                <Route path="/hotel/:id" element={<Hotel />} />
                <Route path="/updateHotel/:id" element={<UpdateHotel />} />
                <Route path="/restaurants" element={<Restaurants />} />
                <Route
                  path="/createRestaurant"
                  element={<CreateRestaurant />}
                />
                <Route path="/restaurant/:id" element={<Restaurant />} />
                <Route
                  path="/updateRestaurant/:id"
                  element={<UpdateRestaurant />}
                />
                <Route path="/createMenu/:id" element={<CreateMenu />} />
                <Route path="/updateMenu/:id" element={<UpdateMenu />} />
                <Route path="/createRoom/:id" element={<CreateRoom />} />
                <Route path="/room/:id" element={<Room />} />
                <Route path="/updateRoom/:id" element={<UpdateRoom />} />
                <Route path="/users" element={<Users />} />
                <Route path="/faqs" element={<FAQs />} />
                <Route path="/createFAQ" element={<CreateFAQ />} />
                <Route path="/updateFAQ/:id" element={<UpdateFAQ />} />
                <Route
                  path="/customerMessages"
                  element={<CustomerMessages />}
                />
                <Route
                  path="/emergencyNumbers"
                  element={<EmergencyNumbers />}
                />
                <Route
                  path="/updateEmergencyNumber/:id"
                  element={<UpdateEmergencyNumber />}
                />
                <Route
                  path="/createEmergencyNumber"
                  element={<CreateEmergencyNumber />}
                />
                <Route path="/admins" element={<Admins />} />
                <Route path="/createAdmin" element={<CreateAdmin />} />
                <Route path="/updateAdmin/:id" element={<UpdateAdmin />} />
                <Route
                  path="/*"
                  element={<Navigate to="/dashboard" replace />}
                />
              </Routes>
            )}
            {auth.role === "hotel" && (
              <HotelContext.Provider value={{ hotel, setHotel }}>
                <Routes>
                  <Route path="/updateAdmin/:id" element={<UpdateAdmin />} />
                  <Route path="/createHotel" element={<CreateHotel />} />
                  <Route path="/hotel" element={<OwnHotel />} />
                  {hotel !== undefined && (
                    <>
                      <Route
                        path="/updateHotel/:id"
                        element={<UpdateHotel />}
                      />
                      <Route path="/rooms" element={<OwnRooms />} />
                      <Route path="/room/:id" element={<Room />} />
                      <Route path="/createRoom/:id" element={<CreateRoom />} />
                      <Route path="/updateRoom/:id" element={<UpdateRoom />} />
                      <Route
                        path="/promotions"
                        element={<OwnHotelPromotions />}
                      />
                      <Route
                        path="/createPromotion"
                        element={<CreateHotelPromotion />}
                      />
                      <Route
                        path="/updatePromotion/:id"
                        element={<UpdateHotelPromotion />}
                      />
                    </>
                  )}
                  <Route path="/*" element={<Navigate to="/hotel" replace />} />
                </Routes>
              </HotelContext.Provider>
            )}
            {auth.role === "restaurant" && (
              <RestaurantContext.Provider value={{ restaurant, setRestaurant }}>
                <Routes>
                  <Route path="/updateAdmin/:id" element={<UpdateAdmin />} />
                  <Route
                    path="/createRestaurant"
                    element={<CreateRestaurant />}
                  />
                  <Route path="/restaurant" element={<OwnRestaurant />} />
                  {restaurant !== undefined && (
                    <>
                      <Route
                        path="/updateRestaurant/:id"
                        element={<UpdateRestaurant />}
                      />
                      <Route path="/menus" element={<OwnMenus />} />
                      <Route path="/createMenu/:id" element={<CreateMenu />} />
                      <Route path="/updateMenu/:id" element={<UpdateMenu />} />
                      <Route
                        path="/promotions"
                        element={<OwnRestaurantPromotions />}
                      />
                      <Route
                        path="/createPromotion"
                        element={<CreateRestaurantPromotion />}
                      />
                      <Route
                        path="/updatePromotion/:id"
                        element={<UpdateRestaurantPromotion />}
                      />
                    </>
                  )}
                  <Route
                    path="/*"
                    element={<Navigate to="/restaurant" replace />}
                  />
                </Routes>
              </RestaurantContext.Provider>
            )}
          </AuthContext.Provider>
        </Suspense>
      </Main>
    </Box>
  );
}
