import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import blogService from "../../../services/blog";
import uploadService from "../../../services/upload";
import {
  Card,
  CardContent,
  CardActions,
  Box,
  Typography,
  FormControl,
  TextField,
  CardMedia,
  Alert,
  Breadcrumbs,
  FormHelperText,
  FormLabel,
  // Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { instanceToken } from "../../../utils/constant";
import { Link } from "react-router-dom";

const imgFileTypes = ["image/gif", "image/jpeg", "image/png", "image/svg+xml"];

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link"],
    ["clean"],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
];

const styles = {
  width: "620px",
  height: "200px",
};

export default function CreateBlog() {
  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [isImageChange, setIsImageChange] = useState(false);
  const [showAlert, setShowAlert] = useState({ message: "", isError: false });

  const [values, setValues] = useState({
    title: "",
    topic: "",
    picture: "",
    titleMm: "",
    topicMm: "",
  });
  const [description, setDescription] = useState("");
  const [descriptionMm, setDescriptionMm] = useState("");
  const [errors, setErrors] = useState({});

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const imgFileSelect = async (e) => {
    if (e.target.files && e.target.files[0]) {
      const img = e.target.files[0];
      if (!imgFileTypes.includes(img.type)) {
        setErrors({
          ...errors,
          profile: "Please select image. (PNG, JPG, JPEG, GIF, ...)",
        });
        return;
      }
      if (img.size > 10485760) {
        setErrors({
          ...errors,
          profile: "Image file size must be smaller than 10MB.",
        });
        return;
      }
      setPreview(URL.createObjectURL(img));
      setImageFile(img);
      setIsImageChange(true);
    }
  };

  const handleImgUpload = async (url, imageFile) => {
    try {
      await uploadService.uploadImage(url, imageFile);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchImageUrl = async () => {
    try {
      const res = await uploadService.getImageUrl(instanceToken.token);
      if (res.data) {
        return {
          url: res.data.imageUploadUrl,
          name: `https://axra.sgp1.digitaloceanspaces.com/GoWithAx/${res.data.imageName}`,
        };
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCreate = async () => {
    setErrors({});
    let err = {};
    if (!values.title) {
      err.title = "Title field is required";
    }
    if (values.title.length > 100) {
      err.title = "Please enter at most 80 characters";
    }
    if (!values.topic) {
      err.topic = "Topic field is required";
    }
    if (values.topic.length > 180) {
      err.topic = "Please enter at most 180 characters";
    }
    if (!values.titleMm) {
      err.titleMm = "Title (Myanmar) field is required";
    }
    if (values.titleMm.length > 100) {
      err.titleMm = "Please enter at most 80 characters";
    }
    if (!values.topicMm) {
      err.topicMm = "Topic (Myanmar) field is required";
    }
    if (values.topicMm.length > 180) {
      err.topicMm = "Please enter at most 180 characters";
    }
    if (!description) {
      err.description = "Description field is required";
    }
    if (!descriptionMm) {
      err.descriptionMm = "Description (Myanmar) field is required";
    }
    if (Object.getOwnPropertyNames(err).length > 0) {
      setErrors({ ...err });
      return;
    }
    try {
      setLoading(true);
      let data = { ...values, description, descriptionMm };

      if (isImageChange) {
        const img = imageFile;
        const { url, name } = await fetchImageUrl();
        await handleImgUpload(url, img);
        data = { ...data, picture: name };
      }

      await blogService.postBlog(instanceToken.token, data);

      setValues({
        title: "",
        topic: "",
        picture: "",
        titleMm: "",
        topicMm: "",
      });
      setDescription("");
      setDescriptionMm("");
      setPreview(null);
      setImageFile(null);
      setIsImageChange(false);
      setShowAlert({
        message: "Blog have been created.",
        isError: false,
      });
    } catch (error) {
      console.log(error);
      setShowAlert({
        message: "Error on server!",
        isError: true,
      });
    } finally {
      setLoading(false);
      setTimeout(() => {
        setShowAlert({ message: "", isError: false });
      }, 4000);
    }
  };

  return (
    <>
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" to="/blogs">
            blogs
          </Link>
          <Typography color="text.primary">create blog</Typography>
        </Breadcrumbs>
      </div>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <Card sx={{ minWidth: 300, height: "auto" }}>
          <CardMedia component="img" height="294" image={preview} />
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                mt: 2,
                height: "auto",
              }}
            >
              <Box sx={{ flex: 5 }}>
                <Box sx={{ width: "100%" }}>
                  <FormLabel>Description</FormLabel>
                  <ReactQuill
                    theme="snow"
                    value={description}
                    onChange={setDescription}
                    modules={modules}
                    formats={formats}
                    style={styles}
                  />
                  {errors.description && (
                    <FormHelperText error>{errors.description}</FormHelperText>
                  )}
                </Box>
                <Box sx={{ mt: 6, width: "100%" }}>
                  <FormLabel>Description (Myanmar)</FormLabel>
                  <ReactQuill
                    theme="snow"
                    value={descriptionMm}
                    onChange={setDescriptionMm}
                    modules={modules}
                    formats={formats}
                    style={styles}
                  />
                </Box>
                {errors.descriptionMm && (
                  <FormHelperText error>{errors.descriptionMm}</FormHelperText>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  p: 0,
                  m: 0,
                  mt: 3,
                  flex: 2,
                }}
              >
                <FormControl
                  sx={{ m: 2, mt: 0, width: 400 }}
                  variant="outlined"
                >
                  <TextField
                    id="title"
                    label="Title"
                    value={values.title}
                    onChange={handleChange("title")}
                    error={errors.title ? true : false}
                    helperText={errors.title}
                  />
                </FormControl>
                <FormControl sx={{ m: 2, width: 400 }} variant="outlined">
                  <TextField
                    id="title_mm"
                    label="Title (Myanmar)"
                    value={values.titleMm}
                    onChange={handleChange("titleMm")}
                    error={errors.titleMm ? true : false}
                    helperText={errors.titleMm}
                  />
                </FormControl>
                <FormControl sx={{ m: 2, width: 400 }} variant="outlined">
                  <TextField
                    id="topic"
                    label="Topic"
                    multiline
                    value={values.topic}
                    onChange={handleChange("topic")}
                    error={errors.topic ? true : false}
                    helperText={errors.topic}
                  />
                </FormControl>
                <FormControl sx={{ m: 2, width: 400 }} variant="outlined">
                  <TextField
                    id="topic_mm"
                    label="Topic (Myanmar)"
                    multiline
                    value={values.topicMm}
                    onChange={handleChange("topicMm")}
                    error={errors.topicMm ? true : false}
                    helperText={errors.topicMm}
                  />
                </FormControl>
                <FormControl sx={{ m: 2, width: 400 }} variant="outlined">
                  <TextField
                    id="image"
                    placeholder="Upload image"
                    InputLabelProps={{ shrink: true }}
                    label="Upload Image"
                    onChange={imgFileSelect}
                    error={errors.picture ? true : false}
                    helperText={errors.picture}
                    type="file"
                    accept={imgFileTypes}
                  />
                </FormControl>
              </Box>
            </Box>
          </CardContent>
          <CardActions sx={{ justifyContent: "end" }}>
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={handleCreate}
              sx={{ backgroundColor: "#4b26d1", alignSelf: "end" }}
            >
              Create
            </LoadingButton>
          </CardActions>
        </Card>
      </Box>
      {showAlert.message && !showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="success"
        >
          {showAlert.message}
        </Alert>
      )}
      {showAlert.message && showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="warning"
        >
          {showAlert.message}
        </Alert>
      )}
    </>
  );
}
