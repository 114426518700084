import React, { useEffect, useState } from "react";
import cityService from "../../../services/city";
import stateService from "../../../services/state";
import uploadService from "../../../services/upload";
import {
  Card,
  CardContent,
  CardActions,
  Box,
  Typography,
  FormControl,
  TextField,
  Alert,
  Breadcrumbs,
  ImageList,
  ImageListItem,
  Button,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { LoadingButton } from "@mui/lab";
import { instanceToken } from "../../../utils/constant";
import { Link } from "react-router-dom";

const imgFileTypes = ["image/gif", "image/jpeg", "image/png", "image/svg+xml"];

export default function CreateCity() {
  const [loading, setLoading] = useState(false);
  const [imageFiles, setImageFiles] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [isImageChange, setIsImageChange] = useState(false);
  const [states, setStates] = useState(null);
  const [showAlert, setShowAlert] = useState({ message: "", isError: false });
  const [values, setValues] = useState({
    name: "",
    description: "",
    recommandation: "",
    highlight: "",
    nameMm: "",
    descriptionMm: "",
    recommandationMm: "",
    highlightMm: "",
    lat: "",
    long: "",
    stateId: "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    fetchStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchStates = async () => {
    try {
      const res = await stateService.getStates();
      setStates(res.data.states);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };

  if (!states) {
    return <em className="placeholder">Loading...</em>;
  }

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const imgFileSelect = async (e) => {
    if (e.target.files && e.target.files[0]) {
      const fileList = e.target.files;
      const files = [];
      const preview = [];
      for (let i = 0, numFiles = fileList.length; i < numFiles; i++) {
        const img = fileList[i];
        if (!imgFileTypes.includes(img.type)) {
          setErrors({
            ...errors,
            profile: "Please select image. (PNG, JPG, JPEG, GIF, ...)",
          });
          return;
        }
        if (img.size > 10485760) {
          setErrors({
            ...errors,
            profile: "Image file size must be smaller than 10MB.",
          });
          return;
        }
        setIsImageChange(true);
        files.push(img);
        preview.push(URL.createObjectURL(img));
      }
      setPreviews(preview);
      setImageFiles(files);
    }
  };

  const handleImgUpload = async (url, imageFile) => {
    try {
      await uploadService.uploadImage(url, imageFile);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchImageUrl = async () => {
    try {
      const res = await uploadService.getImageUrl(instanceToken.token);
      if (res.data) {
        return {
          url: res.data.imageUploadUrl,
          name: `https://axra.sgp1.digitaloceanspaces.com/GoWithAx/${res.data.imageName}`,
        };
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCreate = async () => {
    setErrors({});
    let err = {};
    if (!values.name) {
      err.name = "Name field is required";
    }
    if (!values.description) {
      err.description = "Description field is required";
    }
    if (!values.recommandation) {
      err.recommandation = "Recommandation field is required";
    }
    if (!values.highlight) {
      err.highlight = "Highlight field is required";
    }
    if (values.lat && isNaN(values.lat)) {
      err.lat = "Latitude field must be number";
    }
    if (values.long && isNaN(values.long)) {
      err.long = "Longitude field must be number";
    }
    if ((values.lat && !values.long) || (values.long && !values.lat)) {
      err.lat = "Please enter both Latitude and Longitude";
      err.long = "Please enter both Latitude and Longitude";
    }
    if (!values.nameMm) {
      err.nameMm = "Name (Myanmar) field is required";
    }
    if (!values.descriptionMm) {
      err.descriptionMm = "Description (Myanmar) field is required";
    }
    if (!values.recommandationMm) {
      err.recommandationMm = "Recommandation (Myanmar) field is required";
    }
    if (!values.highlightMm) {
      err.highlightMm = "Highlight (Myanmar) field is required";
    }
    if (Object.getOwnPropertyNames(err).length > 0) {
      setErrors({ ...err });
      return;
    }
    try {
      setLoading(true);
      let data = values;
      if (isImageChange) {
        const fileNames = [];
        for (let i = 0, numFiles = imageFiles.length; i < numFiles; i++) {
          const img = imageFiles[i];
          const { url, name } = await fetchImageUrl();
          await handleImgUpload(url, img);
          fileNames.push(name);
        }
        const names = fileNames.join("||");
        data = { ...data, pictures: names };
      }
      await cityService.postCity(instanceToken.token, data);
      setValues({
        name: "",
        description: "",
        recommandation: "",
        highlight: "",
        nameMm: "",
        descriptionMm: "",
        recommandationMm: "",
        highlightMm: "",
        lat: "",
        long: "",
        stateId: "",
      });
      setPreviews([]);
      setImageFiles([]);
      setIsImageChange(false);
      setShowAlert({
        message: "City have been created.",
        isError: false,
      });
    } catch (error) {
      console.log(error);
      setShowAlert({
        message: "Error on server!",
        isError: true,
      });
    } finally {
      setLoading(false);
      setTimeout(() => {
        setShowAlert({ message: "", isError: false });
      }, 4000);
    }
  };

  return (
    <>
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" to="/cities">
            cities
          </Link>
          <Typography color="text.primary">create city</Typography>
        </Breadcrumbs>
      </div>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <Card sx={{ minWidth: 300, height: "auto" }}>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                mt: 2,
                height: "auto",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <FormControl
                  sx={{ m: 2, mt: 0, width: 500 }}
                  variant="outlined"
                >
                  <TextField
                    id="name"
                    label="Name"
                    value={values.name}
                    onChange={handleChange("name")}
                    error={errors.name ? true : false}
                    helperText={errors.name}
                  />
                </FormControl>
                <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                  <TextField
                    id="name_mm"
                    label="Name (Myanmar)"
                    value={values.nameMm}
                    onChange={handleChange("nameMm")}
                    error={errors.nameMm ? true : false}
                    helperText={errors.nameMm}
                  />
                </FormControl>
                <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                  <InputLabel id="stateId">Select state</InputLabel>
                  <Select
                    labelId="stateId"
                    value={values.stateId}
                    label="Select city"
                    onChange={handleChange("stateId")}
                    error={errors.stateId ? true : false}
                  >
                    <MenuItem value=""></MenuItem>
                    {states.map((s, index) => (
                      <MenuItem key={index} value={s.id}>
                        {s.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.stateId && (
                    <FormHelperText error>{errors.stateId}</FormHelperText>
                  )}
                </FormControl>
                <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                  <TextField
                    id="lat"
                    label="Latitude"
                    value={values.lat}
                    onChange={handleChange("lat")}
                    error={errors.lat ? true : false}
                    helperText={errors.lat}
                  />
                </FormControl>
                <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                  <TextField
                    id="long"
                    label="Longitude"
                    value={values.long}
                    onChange={handleChange("long")}
                    error={errors.long ? true : false}
                    helperText={errors.long}
                  />
                </FormControl>
                <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                  <TextField
                    id="description"
                    label="Description"
                    multiline
                    value={values.description}
                    onChange={handleChange("description")}
                    error={errors.description ? true : false}
                    helperText={errors.description}
                    rows={6}
                  />
                </FormControl>
                <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                  <TextField
                    id="description_mm"
                    label="Description (Myanmar)"
                    multiline
                    value={values.descriptionMm}
                    onChange={handleChange("descriptionMm")}
                    error={errors.descriptionMm ? true : false}
                    helperText={errors.descriptionMm}
                    rows={6}
                  />
                </FormControl>
                <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                  <Button
                    variant="contained"
                    component="label"
                    size="large"
                    sx={{ p: 2 }}
                  >
                    <PhotoCamera />
                    {errors.pictures ? (
                      <Typography sx={{ ml: 1, color: "red" }}>
                        {errors.pictures}
                      </Typography>
                    ) : (
                      <Typography sx={{ ml: 1 }}>Upload Pictures</Typography>
                    )}
                    <input
                      hidden
                      onChange={imgFileSelect}
                      accept={imgFileTypes}
                      multiple
                      type="file"
                    />
                  </Button>
                </FormControl>
                <ImageList
                  sx={{ width: 500, height: "auto", m: 2 }}
                  cols={3}
                  rowHeight={154}
                >
                  {previews.length > 0 &&
                    previews.map((img, index) => (
                      <ImageListItem sx={{ m: 0, p: 0 }} key={index}>
                        <img src={img} alt="" loading="lazy" />
                      </ImageListItem>
                    ))}
                </ImageList>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  p: 0,
                  m: 0,
                }}
              >
                <FormControl
                  sx={{ m: 2, mt: 0, width: 500 }}
                  variant="outlined"
                >
                  <TextField
                    id="recommandation"
                    label="Recommandation"
                    multiline
                    value={values.recommandation}
                    onChange={handleChange("recommandation")}
                    error={errors.recommandation ? true : false}
                    helperText={errors.recommandation}
                    rows={6}
                  />
                </FormControl>
                <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                  <TextField
                    id="recommandation_mm"
                    label="Recommandation (Myanmar)"
                    multiline
                    value={values.recommandationMm}
                    onChange={handleChange("recommandationMm")}
                    error={errors.recommandationMm ? true : false}
                    helperText={errors.recommandationMm}
                    rows={6}
                  />
                </FormControl>
                <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                  <TextField
                    id="highlight"
                    label="Highlight"
                    multiline
                    value={values.highlight}
                    onChange={handleChange("highlight")}
                    error={errors.highlight ? true : false}
                    helperText={errors.highlight}
                    rows={6}
                  />
                </FormControl>
                <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                  <TextField
                    id="highlight_mm"
                    label="Highlight (Myanmar)"
                    multiline
                    value={values.highlightMm}
                    onChange={handleChange("highlightMm")}
                    error={errors.highlightMm ? true : false}
                    helperText={errors.highlightMm}
                    rows={6}
                  />
                </FormControl>
              </Box>
            </Box>
          </CardContent>
          <CardActions sx={{ justifyContent: "end" }}>
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={handleCreate}
              sx={{ backgroundColor: "#4b26d1", alignSelf: "end" }}
            >
              Create
            </LoadingButton>
          </CardActions>
        </Card>
      </Box>
      {showAlert.message && !showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="success"
        >
          {showAlert.message}
        </Alert>
      )}
      {showAlert.message && showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="warning"
        >
          {showAlert.message}
        </Alert>
      )}
    </>
  );
}
