import axios from "axios";
import { BACKEND_URL } from "../utils/constant";

const getCustomerMessages = async (token, limit, offset) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.get(
    `${BACKEND_URL}/api/customer_messages?limit=${limit}&offset=${offset}`,
    config
  );
  return response;
};

const getCustomerMessage = async (token, id) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.get(
    `${BACKEND_URL}/api/customer_messages/${id}`,
    config
  );
  return response;
};

const putCustomerMessage = async (token, id, data) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.put(
    `${BACKEND_URL}/api/customer_messages/${id}`,
    data,
    config
  );
  return response;
};

const deleteCustomerMessage = async (token, id) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.delete(
    `${BACKEND_URL}/api/customer_messages/${id}`,
    config
  );
  return response;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getCustomerMessage,
  putCustomerMessage,
  getCustomerMessages,
  deleteCustomerMessage,
};
