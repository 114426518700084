import axios from "axios";
import { BACKEND_URL } from "../utils/constant";

const getHotels = async (limit, offset) => {
  const response = await axios.get(
    `${BACKEND_URL}/api/hotels?limit=${limit}&offset=${offset}`
  );
  return response;
};

const getHotel = async (id) => {
  const response = await axios.get(`${BACKEND_URL}/api/hotels/${id}`);
  return response;
};

const getHotelByAdminToken = async (token) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.get(
    `${BACKEND_URL}/api/hotels/by_admin_token`,
    config
  );
  return response;
};

const putHotel = async (token, id, data) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.put(
    `${BACKEND_URL}/api/hotels/${id}`,
    data,
    config
  );
  return response;
};

const postHotel = async (token, data) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.post(`${BACKEND_URL}/api/hotels`, data, config);
  return response;
};

const deleteHotel = async (token, id) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.delete(
    `${BACKEND_URL}/api/hotels/${id}`,
    config
  );
  return response;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getHotels,
  putHotel,
  postHotel,
  deleteHotel,
  getHotel,
  getHotelByAdminToken,
};
