import axios from "axios";
import { BACKEND_URL } from "../utils/constant";

const getStates = async (limit, offset) => {
  const response = await axios.get(
    `${BACKEND_URL}/api/states?limit=${limit}&offset=${offset}`
  );
  return response;
};

const getState = async (id) => {
  const response = await axios.get(`${BACKEND_URL}/api/states/${id}`);
  return response;
};

const putState = async (token, id, data) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.put(
    `${BACKEND_URL}/api/states/${id}`,
    data,
    config
  );
  return response;
};

const postState = async (token, data) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.post(`${BACKEND_URL}/api/states`, data, config);
  return response;
};

const deleteState = async (token, id) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.delete(
    `${BACKEND_URL}/api/states/${id}`,
    config
  );
  return response;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getStates,
  putState,
  postState,
  deleteState,
  getState,
};
