import React, { useEffect, useState } from "react";
import blogService from "../../../services/blog";
import { Link } from "react-router-dom";

import {
  TablePagination,
  Breadcrumbs,
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  Box,
  CardMedia,
} from "@mui/material";

export default function Blogs() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState(0);
  const [blogs, setBlogs] = useState(null);

  useEffect(() => {
    fetchBlogs(rowsPerPage, offset);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, rowsPerPage]);

  const fetchBlogs = async (limit, offset) => {
    try {
      const res = await blogService.getBlogs(limit, offset);
      parse(res.data);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };

  const parse = (data) => {
    setBlogs(data.blogs);
    setCount(data.count);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setOffset(rowsPerPage * newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (!blogs) {
    return <em className="placeholder">Loading...</em>;
  }

  return (
    <>
      <div role="presentation" style={{ marginBlockEnd: "10px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <span>blogs</span>
        </Breadcrumbs>
      </div>
      <Button
        sx={{ my: 3 }}
        variant="contained"
        component={Link}
        to={`/createBlog`}
      >
        Create Blog
      </Button>
      <Box
        sx={{
          display: "flex",
          flexFlow: "wrap row",
          "& > :not(style)": {
            m: 1,
            width: "100%",
            minHeight: "25vh",
          },
        }}
      >
        {blogs.map((row, index) => (
          <Card sx={{ maxWidth: 380 }} key={index}>
            <CardMedia component="img" height="190" image={row.picture} />
            <CardContent>
              <Typography variant="h6" component="h6">
                {row.title}
              </Typography>
              <Typography
                variant="p"
                component="p"
                sx={{ height: "50px", overflowX: "auto", wordWrap: "anywhere" }}
              >
                {row.topic}
              </Typography>
            </CardContent>
            <CardActions
              sx={{ py: 0, display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                component={Link}
                to={`/blog/${row.id}`}
                color="info"
                size="small"
              >
                Detail
              </Button>
            </CardActions>
          </Card>
        ))}
      </Box>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
