import React from "react";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Drawer from "@mui/material/Drawer";
import { styled, useTheme } from "@mui/material/styles";
import List from "@mui/material/List";
import { Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { NavLink } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import ContactsIcon from "@mui/icons-material/Contacts";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import WhereToVoteIcon from "@mui/icons-material/WhereToVote";
import HotelIcon from "@mui/icons-material/Hotel";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import PeopleIcon from "@mui/icons-material/People";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import QuizIcon from "@mui/icons-material/Quiz";
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import HouseSidingIcon from "@mui/icons-material/HouseSiding";
import BedIcon from "@mui/icons-material/Bed";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import AdUnitsIcon from "@mui/icons-material/AdUnits";
let activeStyle = {
  textDecoration: "underline",
  color: "#0E26F8",
};

const drawerWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const SideBar = ({ handleDrawerClose, open, auth }) => {
  const theme = useTheme();

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "ltr" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <Box sx={{ overflow: "auto" }} className="nav-container">
        <Divider />
        {auth.role === "master" && (
          <List>
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              to="/dashboard"
              className="nav-link"
            >
              <ListItem disablePadding className="nav-btn">
                <ListItemButton>
                  <ListItemIcon>
                    <DashboardIcon className="nav-link-icon" />
                  </ListItemIcon>
                  <ListItemText primary={"Dashboard"} />
                </ListItemButton>
              </ListItem>
            </NavLink>
            <Divider />
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              to="/blogs"
              className="nav-link"
            >
              <ListItem disablePadding className="nav-btn">
                <ListItemButton>
                  <ListItemIcon>
                    <NewspaperIcon className="nav-link-icon" />
                  </ListItemIcon>
                  <ListItemText primary={"News"} />
                </ListItemButton>
              </ListItem>
            </NavLink>
            <Divider />
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              to="/contacts"
              className="nav-link"
            >
              <ListItem disablePadding className="nav-btn">
                <ListItemButton>
                  <ListItemIcon>
                    <ContactsIcon className="nav-link-icon" />
                  </ListItemIcon>
                  <ListItemText primary={"Contacts"} />
                </ListItemButton>
              </ListItem>
            </NavLink>
            <Divider />
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              to="/cities"
              className="nav-link"
            >
              <ListItem disablePadding className="nav-btn">
                <ListItemButton>
                  <ListItemIcon>
                    <LocationCityIcon className="nav-link-icon" />
                  </ListItemIcon>
                  <ListItemText primary={"Cities"} />
                </ListItemButton>
              </ListItem>
            </NavLink>
            <Divider />
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              to="/places"
              className="nav-link"
            >
              <ListItem disablePadding className="nav-btn">
                <ListItemButton>
                  <ListItemIcon>
                    <WhereToVoteIcon className="nav-link-icon" />
                  </ListItemIcon>
                  <ListItemText primary={"Places"} />
                </ListItemButton>
              </ListItem>
            </NavLink>
            <Divider />
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              to="/hotels"
              className="nav-link"
            >
              <ListItem disablePadding className="nav-btn">
                <ListItemButton>
                  <ListItemIcon>
                    <HotelIcon className="nav-link-icon" />
                  </ListItemIcon>
                  <ListItemText primary={"Hotels"} />
                </ListItemButton>
              </ListItem>
            </NavLink>
            <Divider />
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              to="/restaurants"
              className="nav-link"
            >
              <ListItem disablePadding className="nav-btn">
                <ListItemButton>
                  <ListItemIcon>
                    <RestaurantIcon className="nav-link-icon" />
                  </ListItemIcon>
                  <ListItemText primary={"Restaurants"} />
                </ListItemButton>
              </ListItem>
            </NavLink>
            <Divider />
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              to="/users"
              className="nav-link"
            >
              <ListItem disablePadding className="nav-btn">
                <ListItemButton>
                  <ListItemIcon>
                    <PeopleIcon className="nav-link-icon" />
                  </ListItemIcon>
                  <ListItemText primary={"Users"} />
                </ListItemButton>
              </ListItem>
            </NavLink>
            <Divider />
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              to="/faqs"
              className="nav-link"
            >
              <ListItem disablePadding className="nav-btn">
                <ListItemButton>
                  <ListItemIcon>
                    <QuizIcon className="nav-link-icon" />
                  </ListItemIcon>
                  <ListItemText primary={"FAQs"} />
                </ListItemButton>
              </ListItem>
            </NavLink>
            <Divider />
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              to="/customerMessages"
              className="nav-link"
            >
              <ListItem disablePadding className="nav-btn">
                <ListItemButton>
                  <ListItemIcon>
                    <QuestionAnswerIcon className="nav-link-icon" />
                  </ListItemIcon>
                  <ListItemText primary={"Customer Messages"} />
                </ListItemButton>
              </ListItem>
            </NavLink>
            <Divider />
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              to="/emergencyNumbers"
              className="nav-link"
            >
              <ListItem disablePadding className="nav-btn">
                <ListItemButton>
                  <ListItemIcon>
                    <ContactEmergencyIcon className="nav-link-icon" />
                  </ListItemIcon>
                  <ListItemText primary={"Emergency Numbers"} />
                </ListItemButton>
              </ListItem>
            </NavLink>
            <Divider />
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              to="/states"
              className="nav-link"
            >
              <ListItem disablePadding className="nav-btn">
                <ListItemButton>
                  <ListItemIcon>
                    <HouseSidingIcon className="nav-link-icon" />
                  </ListItemIcon>
                  <ListItemText primary={"States"} />
                </ListItemButton>
              </ListItem>
            </NavLink>
            <Divider />
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              to="/admins"
              className="nav-link"
            >
              <ListItem disablePadding className="nav-btn">
                <ListItemButton>
                  <ListItemIcon>
                    <AdminPanelSettingsIcon className="nav-link-icon" />
                  </ListItemIcon>
                  <ListItemText primary={"Admins"} />
                </ListItemButton>
              </ListItem>
            </NavLink>
          </List>
        )}
        {auth.role === "hotel" && (
          <List>
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              to="/hotel"
              className="nav-link"
            >
              <ListItem disablePadding className="nav-btn">
                <ListItemButton>
                  <ListItemIcon>
                    <HotelIcon className="nav-link-icon" />
                  </ListItemIcon>
                  <ListItemText primary={"Hotel"} />
                </ListItemButton>
              </ListItem>
            </NavLink>
            <Divider />
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              to="/rooms"
              className="nav-link"
            >
              <ListItem disablePadding className="nav-btn">
                <ListItemButton>
                  <ListItemIcon>
                    <BedIcon className="nav-link-icon" />
                  </ListItemIcon>
                  <ListItemText primary={"Rooms"} />
                </ListItemButton>
              </ListItem>
            </NavLink>
            <Divider />
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              to="/promotions"
              className="nav-link"
            >
              <ListItem disablePadding className="nav-btn">
                <ListItemButton>
                  <ListItemIcon>
                    <AdUnitsIcon className="nav-link-icon" />
                  </ListItemIcon>
                  <ListItemText primary={"Promotions"} />
                </ListItemButton>
              </ListItem>
            </NavLink>
          </List>
        )}
        {auth.role === "restaurant" && (
          <List>
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              to="/restaurant"
              className="nav-link"
            >
              <ListItem disablePadding className="nav-btn">
                <ListItemButton>
                  <ListItemIcon>
                    <RestaurantIcon className="nav-link-icon" />
                  </ListItemIcon>
                  <ListItemText primary={"Restaurant"} />
                </ListItemButton>
              </ListItem>
            </NavLink>
            <Divider />
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              to="/menus"
              className="nav-link"
            >
              <ListItem disablePadding className="nav-btn">
                <ListItemButton>
                  <ListItemIcon>
                    <RestaurantMenuIcon className="nav-link-icon" />
                  </ListItemIcon>
                  <ListItemText primary={"Menus"} />
                </ListItemButton>
              </ListItem>
            </NavLink>
            <Divider />
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              to="/promotions"
              className="nav-link"
            >
              <ListItem disablePadding className="nav-btn">
                <ListItemButton>
                  <ListItemIcon>
                    <AdUnitsIcon className="nav-link-icon" />
                  </ListItemIcon>
                  <ListItemText primary={"Promotions"} />
                </ListItemButton>
              </ListItem>
            </NavLink>
          </List>
        )}
      </Box>
    </Drawer>
  );
};

export default SideBar;
