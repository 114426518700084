import React, { useState, useEffect } from "react";
import roomService from "../../../services/room";
import uploadService from "../../../services/upload";
import hotelService from "../../../services/hotel";

import {
  Card,
  CardContent,
  CardActions,
  Box,
  Typography,
  FormControl,
  TextField,
  Alert,
  Breadcrumbs,
  ImageList,
  ImageListItem,
  Button,
} from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { LoadingButton } from "@mui/lab";
import { instanceToken } from "../../../utils/constant";
import { Link, useParams } from "react-router-dom";

const imgFileTypes = ["image/gif", "image/jpeg", "image/png", "image/svg+xml"];

export default function CreateRoom() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [imageFiles, setImageFiles] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [isImageChange, setIsImageChange] = useState(false);
  const [showAlert, setShowAlert] = useState({ message: "", isError: false });
  const [values, setValues] = useState({
    name: "",
    description: "",
    noOfPerson: "",
    bedding: "",
    foodAndBeverage: "",
    bathroom: "",
    roomService: "",
    price: "",
    view: "",
    nameMm: "",
    descriptionMm: "",
    beddingMm: "",
    foodAndBeverageMm: "",
    bathroomMm: "",
  });
  const [errors, setErrors] = useState({});
  const [hotel, setHotel] = useState(null);
  const [placeholder, setPlaceholder] = useState("");

  useEffect(() => {
    if (!hotel && instanceToken.token !== "master") {
      fetchHotel(instanceToken.token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchHotel = async (token) => {
    try {
      const res = await hotelService.getHotelByAdminToken(token);
      setHotel(res.data);
    } catch (error) {
      if (error.response.status === 401) {
        setPlaceholder("Unauthorized");
      } else {
        throw new Error(error);
      }
    }
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const imgFileSelect = async (e) => {
    if (e.target.files && e.target.files[0]) {
      const fileList = e.target.files;
      const files = [];
      const preview = [];
      for (let i = 0, numFiles = fileList.length; i < numFiles; i++) {
        const img = fileList[i];
        if (!imgFileTypes.includes(img.type)) {
          setErrors({
            ...errors,
            profile: "Please select image. (PNG, JPG, JPEG, GIF, ...)",
          });
          return;
        }
        if (img.size > 10485760) {
          setErrors({
            ...errors,
            profile: "Image file size must be smaller than 10MB.",
          });
          return;
        }
        setIsImageChange(true);
        files.push(img);
        preview.push(URL.createObjectURL(img));
      }
      setPreviews(preview);
      setImageFiles(files);
    }
  };

  const handleImgUpload = async (url, imageFile) => {
    try {
      await uploadService.uploadImage(url, imageFile);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchImageUrl = async () => {
    try {
      const res = await uploadService.getImageUrl(instanceToken.token);
      if (res.data) {
        return {
          url: res.data.imageUploadUrl,
          name: `https://axra.sgp1.digitaloceanspaces.com/GoWithAx/${res.data.imageName}`,
        };
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (instanceToken.role !== "master" && !hotel) {
    return <em className="placeholder">{placeholder ?? "Loading..."}</em>;
  }

  const handleCreate = async () => {
    setErrors({});
    let err = {};
    if (!values.name) {
      err.name = "Name field is required";
    }
    if (!values.description) {
      err.description = "Description field is required";
    }
    if (!values.noOfPerson) {
      err.noOfPerson = "Number of person field is required";
    }
    if (values.noOfPerson && isNaN(values.noOfPerson)) {
      err.noOfPerson = "Number of person field must be number";
    }
    if (!values.bedding) {
      err.bedding = "Bedding field is required";
    }
    if (!values.foodAndBeverage) {
      err.foodAndBeverage = "Food and Beverage field is required";
    }
    if (!values.bathroom) {
      err.bathroom = "Bathroom field is required";
    }
    if (!values.roomService) {
      err.roomService = "Room service field is required";
    }
    if (!values.view) {
      err.view = "View field is required";
    }
    if (!values.price) {
      err.price = "Price field is required";
    }
    if (!values.nameMm) {
      err.name = "Name (Myanmar) field is required";
    }
    if (!values.descriptionMm) {
      err.descriptionMm = "Description (Myanmar) field is required";
    }
    if (!values.beddingMm) {
      err.beddingMm = "Bedding (Myanmar) field is required";
    }
    if (!values.foodAndBeverageMm) {
      err.foodAndBeverageMm = "Food and Beverage (Myanmar) field is required";
    }
    if (!values.bathroomMm) {
      err.bathroomMm = "Bathroom (Myanmar) field is required";
    }
    if (Object.getOwnPropertyNames(err).length > 0) {
      setErrors({ ...err });
      return;
    }
    try {
      setLoading(true);
      let data = values;
      if (isImageChange) {
        const fileNames = [];
        for (let i = 0, numFiles = imageFiles.length; i < numFiles; i++) {
          const img = imageFiles[i];
          const { url, name } = await fetchImageUrl();
          await handleImgUpload(url, img);
          fileNames.push(name);
        }
        const names = fileNames.join("||");
        data = { ...data, pictures: names };
      }
      await roomService.postRoom(instanceToken.token, {
        ...data,
        hotelId: instanceToken.role === "master" ? id : hotel.id,
      });
      setValues({
        name: "",
        description: "",
        noOfPerson: "",
        bedding: "",
        foodAndBeverage: "",
        bathroom: "",
        roomService: "",
        price: "",
        view: "",
        nameMm: "",
        descriptionMm: "",
        beddingMm: "",
        foodAndBeverageMm: "",
        bathroomMm: "",
      });
      setPreviews([]);
      setImageFiles([]);
      setIsImageChange(false);
      setShowAlert({
        message: "room have been created.",
        isError: false,
      });
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        setShowAlert({
          message: "Unauthorized",
          isError: true,
        });
      } else {
        setShowAlert({
          message: "Error on server!",
          isError: true,
        });
      }
    } finally {
      setLoading(false);
      setTimeout(() => {
        setShowAlert({ message: "", isError: false });
      }, 4000);
    }
  };

  return (
    <>
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb">
          {instanceToken.role === "master" && (
            <Link underline="hover" color="inherit" to={`/hotel/${id}`}>
              hotel (ID - {id})
            </Link>
          )}
          {instanceToken.role === "hotel" && (
            <Link underline="hover" color="inherit" to={`/rooms`}>
              rooms
            </Link>
          )}
          <Typography color="text.primary">create room</Typography>
        </Breadcrumbs>
      </div>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <Card sx={{ minWidth: 300, height: "auto" }}>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                mt: 2,
                height: "auto",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <FormControl
                  sx={{ m: 2, mt: 0, width: 500 }}
                  variant="outlined"
                >
                  <TextField
                    id="name"
                    label="Name"
                    value={values.name}
                    onChange={handleChange("name")}
                    error={errors.name ? true : false}
                    helperText={errors.name}
                  />
                </FormControl>
                <FormControl
                  sx={{ m: 2, mt: 0, width: 500 }}
                  variant="outlined"
                >
                  <TextField
                    id="name_mm"
                    label="Name (Myanmar)"
                    value={values.nameMm}
                    onChange={handleChange("nameMm")}
                    error={errors.nameMm ? true : false}
                    helperText={errors.nameMm}
                  />
                </FormControl>
                <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                  <TextField
                    id="noOfPerson"
                    label="Number of person"
                    value={values.noOfPerson}
                    onChange={handleChange("noOfPerson")}
                    error={errors.noOfPerson ? true : false}
                    helperText={errors.noOfPerson}
                  />
                </FormControl>
                <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                  <TextField
                    id="price"
                    label="Price"
                    value={values.price}
                    onChange={handleChange("price")}
                    error={errors.price ? true : false}
                    helperText={errors.price}
                  />
                </FormControl>
                <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                  <TextField
                    id="view"
                    label="View"
                    value={values.view}
                    onChange={handleChange("view")}
                    error={errors.view ? true : false}
                    helperText={errors.view}
                  />
                </FormControl>
                <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                  <Button
                    variant="contained"
                    component="label"
                    size="large"
                    sx={{ p: 2 }}
                  >
                    <PhotoCamera />
                    {errors.pictures ? (
                      <Typography sx={{ ml: 1, color: "red" }}>
                        {errors.pictures}
                      </Typography>
                    ) : (
                      <Typography sx={{ ml: 1 }}>Upload Pictures</Typography>
                    )}
                    <input
                      hidden
                      onChange={imgFileSelect}
                      accept={imgFileTypes}
                      multiple
                      type="file"
                    />
                  </Button>
                </FormControl>
                <ImageList
                  sx={{ width: 500, height: "auto", m: 2 }}
                  cols={3}
                  rowHeight={154}
                >
                  {previews.length > 0 &&
                    previews.map((img, index) => (
                      <ImageListItem sx={{ m: 0, p: 0 }} key={index}>
                        <img src={img} alt="" loading="lazy" />
                      </ImageListItem>
                    ))}
                </ImageList>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  p: 0,
                  m: 0,
                }}
              >
                <FormControl
                  sx={{ m: 2, mt: 0, width: 500 }}
                  variant="outlined"
                >
                  <TextField
                    id="bedding"
                    label="Bedding"
                    value={values.bedding}
                    onChange={handleChange("bedding")}
                    error={errors.bedding ? true : false}
                    helperText={errors.bedding}
                  />
                </FormControl>
                <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                  <TextField
                    id="bedding_mm"
                    label="Bedding (Myanmar)"
                    value={values.beddingMm}
                    onChange={handleChange("beddingMm")}
                    error={errors.beddingMm ? true : false}
                    helperText={errors.beddingMm}
                  />
                </FormControl>
                <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                  <TextField
                    id="food_and_beverage"
                    label="Food and Beverage"
                    value={values.foodAndBeverage}
                    onChange={handleChange("foodAndBeverage")}
                    error={errors.foodAndBeverage ? true : false}
                    helperText={errors.foodAndBeverage}
                  />
                </FormControl>
                <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                  <TextField
                    id="food_and_beverage_mm"
                    label="Food and Beverage (Myanmar)"
                    value={values.foodAndBeverageMm}
                    onChange={handleChange("foodAndBeverageMm")}
                    error={errors.foodAndBeverageMm ? true : false}
                    helperText={errors.foodAndBeverageMm}
                  />
                </FormControl>
                <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                  <TextField
                    id="bathroom"
                    label="Bathroom"
                    value={values.bathroom}
                    onChange={handleChange("bathroom")}
                    error={errors.bathroom ? true : false}
                    helperText={errors.bathroom}
                  />
                </FormControl>
                <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                  <TextField
                    id="bathroom_mm"
                    label="Bathroom (Myanmar)"
                    value={values.bathroomMm}
                    onChange={handleChange("bathroomMm")}
                    error={errors.bathroomMm ? true : false}
                    helperText={errors.bathroomMm}
                  />
                </FormControl>
                <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                  <TextField
                    id="description"
                    label="Description"
                    multiline
                    value={values.description}
                    onChange={handleChange("description")}
                    error={errors.description ? true : false}
                    helperText={errors.description}
                    rows={6}
                  />
                </FormControl>
                <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                  <TextField
                    id="description_mm"
                    label="Description (Myanmar)"
                    multiline
                    value={values.descriptionMm}
                    onChange={handleChange("descriptionMm")}
                    error={errors.descriptionMm ? true : false}
                    helperText={errors.descriptionMm}
                    rows={6}
                  />
                </FormControl>
                <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                  <TextField
                    id="roomService"
                    label="Room Service"
                    multiline
                    value={values.roomService}
                    onChange={handleChange("roomService")}
                    error={errors.roomService ? true : false}
                    helperText={errors.roomService}
                    rows={6}
                  />
                </FormControl>
              </Box>
            </Box>
          </CardContent>
          <CardActions sx={{ justifyContent: "end" }}>
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={handleCreate}
              sx={{ backgroundColor: "#4b26d1", alignSelf: "end" }}
            >
              Create
            </LoadingButton>
          </CardActions>
        </Card>
      </Box>
      {showAlert.message && !showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="success"
        >
          {showAlert.message}
        </Alert>
      )}
      {showAlert.message && showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="warning"
        >
          {showAlert.message}
        </Alert>
      )}
    </>
  );
}
