import axios from "axios";
import { BACKEND_URL } from "../utils/constant";

const getRestaurants = async (limit, offset) => {
  const response = await axios.get(
    `${BACKEND_URL}/api/restaurants?limit=${limit}&offset=${offset}`
  );
  return response;
};

const getRestaurant = async (id) => {
  const response = await axios.get(`${BACKEND_URL}/api/restaurants/${id}`);
  return response;
};

const getRestaurantByAdminToken = async (token) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.get(
    `${BACKEND_URL}/api/restaurants/by_admin_token`,
    config
  );
  return response;
};

const putRestaurant = async (token, id, data) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.put(
    `${BACKEND_URL}/api/restaurants/${id}`,
    data,
    config
  );
  return response;
};

const postRestaurant = async (token, data) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.post(
    `${BACKEND_URL}/api/restaurants`,
    data,
    config
  );
  return response;
};

const deleteRestaurant = async (token, id) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.delete(
    `${BACKEND_URL}/api/restaurants/${id}`,
    config
  );
  return response;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getRestaurants,
  putRestaurant,
  postRestaurant,
  deleteRestaurant,
  getRestaurant,
  getRestaurantByAdminToken,
};
