import axios from "axios";
import { BACKEND_URL } from "../utils/constant";

const getCounts = async () => {
  const response = await axios.get(`${BACKEND_URL}/api/dashboards/counts`);
  return response;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getCounts,
};
