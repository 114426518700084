import React, { useState, useEffect, useContext } from "react";
import hotelService from "../../../services/hotel";
import uploadService from "../../../services/upload";
import cityService from "../../../services/city";

import {
  Card,
  CardContent,
  CardActions,
  Box,
  Typography,
  FormControl,
  TextField,
  Alert,
  Breadcrumbs,
  ImageList,
  ImageListItem,
  Button,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  FormGroup,
  FormLabel,
} from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { LoadingButton } from "@mui/lab";
import { instanceToken } from "../../../utils/constant";
import { Link } from "react-router-dom";
import { HotelContext } from "../../Home";

const imgFileTypes = ["image/gif", "image/jpeg", "image/png", "image/svg+xml"];

export default function CreateHotel() {
  const [cities, setCities] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imageFiles, setImageFiles] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [isImageChange, setIsImageChange] = useState(false);
  const [showAlert, setShowAlert] = useState({ message: "", isError: false });
  const [values, setValues] = useState({
    name: "",
    description: "",
    stars: "",
    checkIn: "",
    checkOut: "",
    paymentCancellation: "",
    pets: "",
    phones: "",
    email: "",
    address: "",
    cityId: "",
    lat: "",
    long: "",
    nameMm: "",
    descriptionMm: "",
    addressMm: "",
    paymentCancellationMm: "",
    petsMm: "",
  });
  const [allChecked, setAllChecked] = useState({
    wifi: false,
    breakfast: false,
    bar: false,
    pool: false,
    parking: false,
    garden: false,
    spa: false,
  });
  const [errors, setErrors] = useState({});
  const [alreadyExist, setAlreadyExist] = useState(false);
  const [placeholder, setPlaceholder] = useState("");
  const { setHotel } = useContext(HotelContext);

  useEffect(() => {
    if (instanceToken.role !== "master") {
      fetchHotelByAdminToken(instanceToken.token);
    }
    fetchCities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCities = async () => {
    try {
      const res = await cityService.getCities();
      setCities(res.data.cities);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };

  const fetchHotelByAdminToken = async (token) => {
    try {
      const res = await hotelService.getHotelByAdminToken(token);
      if (res.data) {
        setHotel(res.data);
        setAlreadyExist(true);
      }
    } catch (error) {
      console.error("error : ", error);
      if (error.response.status === 404) {
        setAlreadyExist(false);
      } else if (error.response.status === 401) {
        setPlaceholder("Unauthorized");
      } else {
        console.log(error);
        throw new Error(error);
      }
    }
  };

  if (!cities) {
    return <em className="placeholder">{placeholder ?? "Loading..."}</em>;
  }

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const imgFileSelect = async (e) => {
    if (e.target.files && e.target.files[0]) {
      const fileList = e.target.files;
      const files = [];
      const preview = [];
      for (let i = 0, numFiles = fileList.length; i < numFiles; i++) {
        const img = fileList[i];
        if (!imgFileTypes.includes(img.type)) {
          setErrors({
            ...errors,
            profile: "Please select image. (PNG, JPG, JPEG, GIF, ...)",
          });
          return;
        }
        if (img.size > 10485760) {
          setErrors({
            ...errors,
            profile: "Image file size must be smaller than 10MB.",
          });
          return;
        }
        setIsImageChange(true);
        files.push(img);
        preview.push(URL.createObjectURL(img));
      }
      setPreviews(preview);
      setImageFiles(files);
    }
  };

  const handleImgUpload = async (url, imageFile) => {
    try {
      await uploadService.uploadImage(url, imageFile);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchImageUrl = async () => {
    try {
      const res = await uploadService.getImageUrl(instanceToken.token);
      if (res.data) {
        return {
          url: res.data.imageUploadUrl,
          name: `https://axra.sgp1.digitaloceanspaces.com/GoWithAx/${res.data.imageName}`,
        };
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCreate = async () => {
    setErrors({});
    let err = {};
    const amenities = [];
    for (const [key, value] of Object.entries(allChecked)) {
      if (value) {
        amenities.push(key);
      }
    }
    if (!values.name) {
      err.name = "Name field is required";
    }
    if (!values.description) {
      err.description = "Description field is required";
    }
    if (!values.stars) {
      err.stars = "Stars field is required";
    }
    if (values.stars && isNaN(values.stars)) {
      err.stars = "Stars field must be number";
    }
    if (!values.checkIn) {
      err.checkIn = "Check In field is required";
    }
    if (!values.checkOut) {
      err.checkOut = "Check Out field is required";
    }
    if (!values.paymentCancellation) {
      err.paymentCancellation = "Payment Cancellation field is required";
    }
    if (!values.pets) {
      err.pets = "Pets field is required";
    }
    if (!values.phones) {
      err.phones = "Phone numbers field is required";
    }
    if (!values.email) {
      err.email = "Email field is required";
    }
    if (!values.address) {
      err.address = "Address field is required";
    }
    if (amenities.length <= 0) {
      err.amenities = "Amenities field is required";
    }
    if (!values.cityId) {
      err.cityId = "City field is required";
    }
    if (values.lat && isNaN(values.lat)) {
      err.lat = "Latitude field must be number";
    }
    if (values.long && isNaN(values.long)) {
      err.long = "Longitude field must be number";
    }
    if ((values.lat && !values.long) || (values.long && !values.lat)) {
      err.lat = "Please enter both Latitude and Longitude";
      err.long = "Please enter both Latitude and Longitude";
    }
    if (!values.nameMm) {
      err.nameMm = "Name (Myanmar) field is required";
    }
    if (!values.descriptionMm) {
      err.descriptionMm = "Description (Myanmar) field is required";
    }
    if (!values.addressMm) {
      err.addressMm = "Address (Myanmar) field is required";
    }
    if (!values.paymentCancellationMm) {
      err.paymentCancellationMm =
        "Payment Cancellation (Myanmar) field is required";
    }
    if (!values.petsMm) {
      err.petsMm = "Pets (Myanmar) field is required";
    }
    if (Object.getOwnPropertyNames(err).length > 0) {
      setErrors({ ...err });
      return;
    }
    try {
      setLoading(true);
      let data = values;
      if (isImageChange) {
        const fileNames = [];
        for (let i = 0, numFiles = imageFiles.length; i < numFiles; i++) {
          const img = imageFiles[i];
          const { url, name } = await fetchImageUrl();
          await handleImgUpload(url, img);
          fileNames.push(name);
        }
        const names = fileNames.join("||");
        data = { ...data, pictures: names };
      }

      const amen = amenities.join(",");
      await hotelService.postHotel(instanceToken.token, {
        ...data,
        amenities: amen,
      });

      setValues({
        name: "",
        description: "",
        stars: "",
        checkIn: "",
        checkOut: "",
        paymentCancellation: "",
        pets: "",
        phones: "",
        email: "",
        address: "",
        cityId: "",
        lat: "",
        long: "",
        nameMm: "",
        descriptionMm: "",
        addressMm: "",
        paymentCancellationMm: "",
        petsMm: "",
      });
      setPreviews([]);
      setImageFiles([]);
      setIsImageChange(false);
      setAllChecked({
        wifi: false,
        breakfast: false,
        bar: false,
        pool: false,
        parking: false,
        garden: false,
        spa: false,
      });
      setShowAlert({
        message: "Hotel have been created.",
        isError: false,
      });
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        setShowAlert({
          message: "Unauthorized",
          isError: true,
        });
      } else {
        setShowAlert({
          message: "Error on server!",
          isError: true,
        });
      }
    } finally {
      setLoading(false);
      setTimeout(() => {
        setShowAlert({ message: "", isError: false });
      }, 4000);
      if (instanceToken.role === "hotel") {
        fetchHotelByAdminToken(instanceToken.token);
      }
    }
  };

  const handleCheckChange = (event) => {
    setAllChecked({
      ...allChecked,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <>
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb">
          {instanceToken.role === "master" && (
            <Link underline="hover" color="inherit" to="/hotels">
              hotels
            </Link>
          )}
          <Typography color="text.primary">create hotel</Typography>
        </Breadcrumbs>
      </div>
      {alreadyExist ? (
        <em className="placeholder">You have created.</em>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <Card sx={{ minWidth: 300, height: "auto" }}>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  mt: 2,
                  height: "auto",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <FormControl
                    sx={{ m: 2, mt: 0, width: 500 }}
                    variant="outlined"
                  >
                    <TextField
                      id="name"
                      label="Name"
                      value={values.name}
                      onChange={handleChange("name")}
                      error={errors.name ? true : false}
                      helperText={errors.name}
                    />
                  </FormControl>
                  <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                    <TextField
                      id="name_mm"
                      label="Name (Myanmar)"
                      value={values.nameMm}
                      onChange={handleChange("nameMm")}
                      error={errors.nameMm ? true : false}
                      helperText={errors.nameMm}
                    />
                  </FormControl>
                  <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                    <TextField
                      id="stars"
                      label="Stars"
                      value={values.stars}
                      onChange={handleChange("stars")}
                      error={errors.stars ? true : false}
                      helperText={errors.stars}
                    />
                  </FormControl>
                  <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                    <TextField
                      id="checkIn"
                      label="Check In"
                      value={values.checkIn}
                      onChange={handleChange("checkIn")}
                      error={errors.checkIn ? true : false}
                      helperText={errors.checkIn}
                    />
                  </FormControl>
                  <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                    <TextField
                      id="checkOut"
                      label="Check Out"
                      value={values.checkOut}
                      onChange={handleChange("checkOut")}
                      error={errors.checkOut ? true : false}
                      helperText={errors.checkOut}
                    />
                  </FormControl>
                  <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                    <TextField
                      id="payment"
                      label="Payment Cancellation"
                      value={values.paymentCancellation}
                      onChange={handleChange("paymentCancellation")}
                      error={errors.paymentCancellation ? true : false}
                      helperText={errors.paymentCancellation}
                    />
                  </FormControl>
                  <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                    <TextField
                      id="payment_mm"
                      label="Payment Cancellation (Myanmar)"
                      value={values.paymentCancellationMm}
                      onChange={handleChange("paymentCancellationMm")}
                      error={errors.paymentCancellationMm ? true : false}
                      helperText={errors.paymentCancellationMm}
                    />
                  </FormControl>
                  <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                    <TextField
                      id="pets"
                      label="Pets"
                      value={values.pets}
                      onChange={handleChange("pets")}
                      error={errors.pets ? true : false}
                      helperText={errors.pets}
                    />
                  </FormControl>
                  <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                    <TextField
                      id="pets_mm"
                      label="Pets (Myanmar)"
                      value={values.petsMm}
                      onChange={handleChange("petsMm")}
                      error={errors.petsMm ? true : false}
                      helperText={errors.petsMm}
                    />
                  </FormControl>
                  <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                    <TextField
                      id="phones"
                      label="Phone Numbers"
                      value={values.phones}
                      onChange={handleChange("phones")}
                      error={errors.phones ? true : false}
                      helperText={errors.phones}
                    />
                  </FormControl>
                  <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                    <TextField
                      id="email"
                      label="Email"
                      type="email"
                      value={values.email}
                      onChange={handleChange("email")}
                      error={errors.email ? true : false}
                      helperText={errors.email}
                    />
                  </FormControl>
                  <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                    <InputLabel id="cityId">Select city</InputLabel>
                    <Select
                      labelId="cityId"
                      value={values.cityId}
                      label="Select city"
                      onChange={handleChange("cityId")}
                      error={errors.cityId ? true : false}
                    >
                      <MenuItem value=""></MenuItem>
                      {cities.map((c, index) => (
                        <MenuItem key={index} value={c.id}>
                          {c.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.cityId && (
                      <FormHelperText error>{errors.cityId}</FormHelperText>
                    )}
                  </FormControl>
                  <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                    <Button
                      variant="contained"
                      component="label"
                      size="large"
                      sx={{ p: 2 }}
                    >
                      <PhotoCamera />
                      {errors.pictures ? (
                        <Typography sx={{ ml: 1, color: "red" }}>
                          {errors.pictures}
                        </Typography>
                      ) : (
                        <Typography sx={{ ml: 1 }}>Upload Pictures</Typography>
                      )}
                      <input
                        hidden
                        onChange={imgFileSelect}
                        accept={imgFileTypes}
                        multiple
                        type="file"
                      />
                    </Button>
                  </FormControl>
                  <ImageList
                    sx={{ width: 500, height: "auto", m: 2 }}
                    cols={3}
                    rowHeight={154}
                  >
                    {previews.length > 0 &&
                      previews.map((img, index) => (
                        <ImageListItem sx={{ m: 0, p: 0 }} key={index}>
                          <img src={img} alt="" loading="lazy" />
                        </ImageListItem>
                      ))}
                  </ImageList>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    p: 0,
                    m: 0,
                  }}
                >
                  <FormControl
                    sx={{ m: 2, mt: 0, width: 500 }}
                    variant="outlined"
                  >
                    <TextField
                      id="lat"
                      label="Latitude"
                      value={values.lat}
                      onChange={handleChange("lat")}
                      error={errors.lat ? true : false}
                      helperText={errors.lat}
                    />
                  </FormControl>
                  <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                    <TextField
                      id="long"
                      label="Longitude"
                      value={values.long}
                      onChange={handleChange("long")}
                      error={errors.long ? true : false}
                      helperText={errors.long}
                    />
                  </FormControl>
                  <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                    <TextField
                      id="address"
                      label="Address"
                      multiline
                      value={values.address}
                      onChange={handleChange("address")}
                      error={errors.address ? true : false}
                      helperText={errors.address}
                      rows={2}
                    />
                  </FormControl>
                  <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                    <TextField
                      id="address_mm"
                      label="Address (Myanmar)"
                      multiline
                      value={values.addressMm}
                      onChange={handleChange("addressMm")}
                      error={errors.addressMm ? true : false}
                      helperText={errors.addressMm}
                      rows={2}
                    />
                  </FormControl>
                  <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                    <TextField
                      id="description"
                      label="Description"
                      multiline
                      value={values.description}
                      onChange={handleChange("description")}
                      error={errors.description ? true : false}
                      helperText={errors.description}
                      rows={6}
                    />
                  </FormControl>
                  <FormControl sx={{ m: 2, width: 500 }} variant="outlined">
                    <TextField
                      id="description_mm"
                      label="Description (Myanmar)"
                      multiline
                      value={values.descriptionMm}
                      onChange={handleChange("descriptionMm")}
                      error={errors.descriptionMm ? true : false}
                      helperText={errors.descriptionMm}
                      rows={6}
                    />
                  </FormControl>
                  <FormControl
                    required
                    error={!!errors.amenities}
                    component="fieldset"
                    sx={{
                      m: 2,
                      width: 500,
                    }}
                    variant="outlined"
                  >
                    <FormLabel component="legend">Amenities</FormLabel>
                    <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={allChecked.wifi}
                            onChange={handleCheckChange}
                            name="wifi"
                          />
                        }
                        label="Wifi"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={allChecked.breakfast}
                            onChange={handleCheckChange}
                            name="breakfast"
                          />
                        }
                        label="breakfast"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={allChecked.bar}
                            onChange={handleCheckChange}
                            name="bar"
                          />
                        }
                        label="bar"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={allChecked.pool}
                            onChange={handleCheckChange}
                            name="pool"
                          />
                        }
                        label="pool"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={allChecked.parking}
                            onChange={handleCheckChange}
                            name="parking"
                          />
                        }
                        label="parking"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={allChecked.garden}
                            onChange={handleCheckChange}
                            name="garden"
                          />
                        }
                        label="garden"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={allChecked.spa}
                            onChange={handleCheckChange}
                            name="spa"
                          />
                        }
                        label="spa"
                      />
                    </FormGroup>
                    {errors.amenities && (
                      <FormHelperText error>{errors.amenities}</FormHelperText>
                    )}
                  </FormControl>
                </Box>
              </Box>
            </CardContent>
            <CardActions sx={{ justifyContent: "end" }}>
              <LoadingButton
                variant="contained"
                loading={loading}
                onClick={handleCreate}
                sx={{ backgroundColor: "#4b26d1", alignSelf: "end" }}
              >
                Create
              </LoadingButton>
            </CardActions>
          </Card>
        </Box>
      )}

      {showAlert.message && !showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="success"
        >
          {showAlert.message}
        </Alert>
      )}
      {showAlert.message && showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="warning"
        >
          {showAlert.message}
        </Alert>
      )}
    </>
  );
}
