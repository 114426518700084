import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import hotelService from "../../../services/hotel";
import uploadService from "../../../services/upload";
import roomService from "../../../services/room";
import {
  Container,
  Card,
  Grid,
  Box,
  Typography,
  CardContent,
  CardActions,
  Button,
  Breadcrumbs,
  Modal,
  Alert,
  ImageList,
  ImageListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
import { instanceToken } from "../../../utils/constant";

const styleR = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Hotel = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [openR, setOpenR] = useState(false);
  const [hotel, setHotel] = useState(null);
  const [showAlert, setShowAlert] = useState({ message: "", isError: false });
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [offset, setOffset] = useState(0);
  const [rooms, setRooms] = useState(null);
  const [go, setGo] = useState(false);

  useEffect(() => {
    if (!hotel) {
      fetchHotel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchHotel = async () => {
    try {
      const res = await hotelService.getHotel(id);
      setHotel(res.data);
    } catch (error) {
      console.error("error : ", error);
      throw new Error(error);
    }
  };

  useEffect(() => {
    if (hotel) {
      fetchRooms(hotel.id, rowsPerPage, offset);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, rowsPerPage, hotel]);

  const fetchRooms = async (id, limit, offset) => {
    try {
      const res = await roomService.getRooms(id, limit, offset);
      parse(res.data);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };

  if (!hotel) {
    return <em className="placeholder">Loading...</em>;
  }

  const parse = (data) => {
    setRooms(data.rooms);
    setCount(data.count);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setOffset(rowsPerPage * newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleOpenR = () => {
    setOpenR(true);
  };
  const handleCloseR = () => {
    setOpenR(false);
  };

  const deleteImage = async (fileName) => {
    try {
      await uploadService.deleteImage(instanceToken.token, {
        imageName: fileName,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleRemove = async () => {
    try {
      if (rooms.length > 0) {
        setShowAlert({
          message: "Please remove all of the room first.",
          isError: true,
        });
        setTimeout(() => {
          setShowAlert({ message: "", isError: false });
        }, 4000);
      } else {
        if (hotel.pictures) {
          hotel.pictures.split("||").forEach(async (picture) => {
            let image_name = picture.substring(
              picture.lastIndexOf("/") + 1,
              picture.length
            );
            await deleteImage(image_name);
          });
        }
        await hotelService.deleteHotel(instanceToken.token, hotel.id);
        setGo(true);
        setShowAlert({
          message: "Hotel have been removed.",
          isError: false,
        });
        setTimeout(() => {
          setGo(false);
          setShowAlert({ message: "", isError: false });
          navigate("/hotels");
        }, 4000);
      }
    } catch (error) {
      console.log(error);
      setShowAlert({
        message: "Error on server!",
        isError: true,
      });
      setTimeout(() => {
        setShowAlert({ message: "", isError: false });
      }, 4000);
    } finally {
      handleCloseR();
    }
  };

  return (
    <>
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" to="/hotels">
            hotels
          </Link>
          <Typography color="text.primary">hotel (ID - {id})</Typography>
        </Breadcrumbs>
      </div>
      {go ? (
        <em className="placeholder">
          It will be automatic navigated to hotels page soon.
        </em>
      ) : (
        <>
          <Container
            maxWidth={false}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              mt: 2,
            }}
          >
            <Card
              sx={{
                minWidth: "70vw",
                display: "grid",
                gridTemplateColumns: "2fr 3fr",
              }}
            >
              <Box>
                {hotel.pictures && (
                  <ImageList
                    sx={{ width: 500, height: 250, mx: 2 }}
                    cols={3}
                    rowHeight={164}
                  >
                    {hotel.pictures.split("||").map((img, index) => (
                      <ImageListItem key={index}>
                        <img
                          src={`${img}?w=164&h=164&fit=crop&auto=format`}
                          srcSet={`${img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                          alt=""
                          loading="lazy"
                        />
                      </ImageListItem>
                    ))}
                  </ImageList>
                )}
                <CardContent sx={{ pr: 5 }}>
                  <Grid sx={{ m: 2 }} container spacing={1}>
                    <Grid item xs={3}>
                      <Typography variant="body2" color="text.secondary">
                        Name
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant="body2" color="text.secondary">
                        {hotel.name}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid sx={{ m: 2 }} container spacing={1}>
                    <Grid item xs={3}>
                      <Typography variant="body2" color="text.secondary">
                        Name (Myanmar)
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant="body2" color="text.secondary">
                        {hotel.nameMm}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid sx={{ m: 2 }} container spacing={1}>
                    <Grid item xs={3}>
                      <Typography variant="body2" color="text.secondary">
                        Check In
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant="body2" color="text.secondary">
                        {hotel.checkIn}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid sx={{ m: 2 }} container spacing={1}>
                    <Grid item xs={3}>
                      <Typography variant="body2" color="text.secondary">
                        Check Out
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant="body2" color="text.secondary">
                        {hotel.checkOut}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid sx={{ m: 2 }} container spacing={1}>
                    <Grid item xs={3}>
                      <Typography variant="body2" color="text.secondary">
                        City
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant="body2" color="text.secondary">
                        {hotel.city?.name}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid sx={{ m: 2 }} container spacing={1}>
                    <Grid item xs={3}>
                      <Typography variant="body2" color="text.secondary">
                        Latitude
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant="body2" color="text.secondary">
                        {hotel.location?.coordinates[0]}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid sx={{ m: 2 }} container spacing={1}>
                    <Grid item xs={3}>
                      <Typography variant="body2" color="text.secondary">
                        Longitude
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant="body2" color="text.secondary">
                        {hotel.location?.coordinates[1]}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid sx={{ m: 2, width: "95%" }} container spacing={1}>
                    <Grid item xs={3}>
                      <Typography variant="body2" color="text.secondary">
                        Stars
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant="body2" color="text.secondary">
                        {hotel.stars}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid sx={{ m: 2, width: "95%" }} container spacing={1}>
                    <Grid item xs={3}>
                      <Typography variant="body2" color="text.secondary">
                        Phone Numbers
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant="body2" color="text.secondary">
                        {hotel.phones}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid sx={{ m: 2, width: "95%" }} container spacing={1}>
                    <Grid item xs={3}>
                      <Typography variant="body2" color="text.secondary">
                        Email
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant="body2" color="text.secondary">
                        {hotel.email}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid sx={{ m: 2, width: "95%" }} container spacing={1}>
                    <Grid item xs={3}>
                      <Typography variant="body2" color="text.secondary">
                        Amenities
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant="body2" color="text.secondary">
                        {hotel.amenities}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid sx={{ m: 2, width: "95%" }} container spacing={1}>
                    <Grid item xs={3}>
                      <Typography variant="body2" color="text.secondary">
                        Created On
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant="body2" color="text.secondary">
                        {hotel.createdAt.substring(0, 10)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid sx={{ m: 2, width: "95%" }} container spacing={1}>
                    <Grid item xs={3}>
                      <Typography variant="body2" color="text.secondary">
                        Pyament Cancellation
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant="body2" color="text.secondary">
                        {hotel.paymentCancellation}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid sx={{ m: 2, width: "95%" }} container spacing={1}>
                    <Grid item xs={3}>
                      <Typography variant="body2" color="text.secondary">
                        Pyament Cancellation (Myanmar)
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant="body2" color="text.secondary">
                        {hotel.paymentCancellationMm}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Box>
              <Box>
                <Grid sx={{ m: 2, width: "95%" }} container spacing={1}>
                  <Grid item xs={3}>
                    <Typography variant="body2" color="text.secondary">
                      Address
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="body2" color="text.secondary">
                      {hotel.address}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid sx={{ m: 2, width: "95%" }} container spacing={1}>
                  <Grid item xs={3}>
                    <Typography variant="body2" color="text.secondary">
                      Address (Myanmar)
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="body2" color="text.secondary">
                      {hotel.addressMm}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid sx={{ m: 2, width: "95%" }} container spacing={1}>
                  <Grid item xs={3}>
                    <Typography variant="body2" color="text.secondary">
                      Description
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="body2" color="text.secondary">
                      {hotel.description}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid sx={{ m: 2, width: "95%" }} container spacing={1}>
                  <Grid item xs={3}>
                    <Typography variant="body2" color="text.secondary">
                      Description (Myanmar)
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="body2" color="text.secondary">
                      {hotel.descriptionMm}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid sx={{ m: 2, width: "95%" }} container spacing={1}>
                  <Grid item xs={3}>
                    <Typography variant="body2" color="text.secondary">
                      Pets
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="body2" color="text.secondary">
                      {hotel.pets}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid sx={{ m: 2, width: "95%" }} container spacing={1}>
                  <Grid item xs={3}>
                    <Typography variant="body2" color="text.secondary">
                      Pets (Myanmar)
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="body2" color="text.secondary">
                      {hotel.petsMm}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <CardActions sx={{ gridColumn: "1/3", justifyContent: "end" }}>
                <Button size="small" color="error" onClick={handleOpenR}>
                  Remove
                </Button>
                <Button
                  size="small"
                  component={Link}
                  to={`/updateHotel/${hotel.id}`}
                >
                  Edit
                </Button>
              </CardActions>
            </Card>
          </Container>
          <Button
            sx={{ my: 3, mx: 4 }}
            variant="contained"
            component={Link}
            to={`/createRoom/${hotel.id}`}
          >
            Create Room
          </Button>
          {!rooms ? (
            <em className="subPlaceholder">Loading...</em>
          ) : (
            <TableContainer component={Paper} sx={{ mx: 3, width: "auto" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Number of person</TableCell>
                    <TableCell>Created On</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rooms.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.price}</TableCell>
                      <TableCell>{row.noOfPerson}</TableCell>
                      <TableCell>{row.createdAt?.substring(0, 10)}</TableCell>
                      <TableCell>
                        <Button
                          component={Link}
                          to={`/room/${row.id}`}
                          color="info"
                          size="small"
                        >
                          Detail
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          )}
        </>
      )}

      <Modal
        keepMounted
        open={openR}
        onClose={handleCloseR}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={styleR}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Confirmation
          </Typography>
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
            Are you sure want to remove it?
          </Typography>
          <Box sx={{ textAlign: "right", mt: 2 }}>
            <Button color="secondary" onClick={handleCloseR}>
              Cancel
            </Button>
            <Button onClick={() => handleRemove()}>Confirm</Button>
          </Box>
        </Box>
      </Modal>
      {showAlert.message && !showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="success"
        >
          {showAlert.message}
        </Alert>
      )}
      {showAlert.message && showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="warning"
        >
          {showAlert.message}
        </Alert>
      )}
    </>
  );
};

export default Hotel;
