import axios from "axios";
import { BACKEND_URL } from "../utils/constant";

const getPlaces = async (limit, offset) => {
  const response = await axios.get(
    `${BACKEND_URL}/api/places?limit=${limit}&offset=${offset}`
  );
  return response;
};

const getPlace = async (id) => {
  const response = await axios.get(`${BACKEND_URL}/api/places/${id}`);
  return response;
};

const putPlace = async (token, id, data) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.put(
    `${BACKEND_URL}/api/places/${id}`,
    data,
    config
  );
  return response;
};

const postPlace = async (token, data) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.post(`${BACKEND_URL}/api/places`, data, config);
  return response;
};

const deletePlace = async (token, id) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.delete(
    `${BACKEND_URL}/api/places/${id}`,
    config
  );
  return response;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getPlaces,
  putPlace,
  postPlace,
  deletePlace,
  getPlace,
};
