import axios from "axios";
import { BACKEND_URL } from "../utils/constant";

const getContacts = async (token, limit, offset) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.get(
    `${BACKEND_URL}/api/contacts?limit=${limit}&offset=${offset}`,
    config
  );
  return response;
};

const deleteContact = async (token, id) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.delete(
    `${BACKEND_URL}/api/contacts/${id}`,
    config
  );
  return response;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getContacts,
  deleteContact,
};
